/* eslint-disable no-continue */
import { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import { FlattenValue, Value } from './types'

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform)

function getMaxDepth({ previousItem }: { previousItem: FlattenValue }) {
  if (previousItem) {
    return previousItem.depth + 1
  }

  return 0
}

function getMinDepth({ nextItem }: { nextItem: FlattenValue }) {
  if (nextItem) {
    return nextItem.depth
  }

  return 0
}

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth)
}

export function getProjection(
  items: FlattenValue[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId)
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = arrayMove(items, activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex - 1]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  const maxDepth = getMaxDepth({
    previousItem,
  })
  const minDepth = getMinDepth({ nextItem })
  let depth = projectedDepth

  if (projectedDepth >= maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId
    }

    if (depth > previousItem.depth) {
      return previousItem.id
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId

    return newParent ?? null
  }
}

export function flatten(
  items: Value[],
  fieldName: string,
  parentName: null | string = null,
  parentIndex: number = 0,
  parentId: number | string | null = null,
  depth = 0
): FlattenValue[] {
  return items.reduce<FlattenValue[]>((acc, item, index, arr) => {
    const name = parentName
      ? `${parentName}[${parentIndex}]${fieldName}.value`
      : `${fieldName}.value`

    if (depth >= 3) return acc

    const children = item[fieldName]?.value ?? []
    const isFirst = index === 0
    const isLast = index === arr.length - 1

    const newValue: FlattenValue = {
      ...item,
      parentId,
      depth,
      name,
      originalIndex: index,
      children,
      isFirst, // ✅ First child in the current depth
      isLast, // ✅ Last child in the current depth
    }

    return [...acc, newValue, ...flatten(children, fieldName, name, index, item.id, depth + 1)]
  }, [])
}

export function flattenTree(items: Value[], fieldName: string): FlattenValue[] {
  return flatten(items, fieldName)
}

export function removeChildrenOf(items: FlattenValue[], ids: UniqueIdentifier[]) {
  const excludeParentIds = [...ids]

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id)
      }
      return false
    }

    return true
  })
}

export const buildTree = (flattened: FlattenValue[], childrenKey: string): Value[] => {
  const itemMap = new Map<UniqueIdentifier, Value>()
  const root: Value[] = []

  flattened.forEach((item) => {
    itemMap.set(item.id, {
      ...item,
      [childrenKey]: { value: [] },
    })
  })

  flattened.forEach((item) => {
    const currentItem = itemMap.get(item.id)!
    if (!item.parentId) {
      root.push(currentItem)
    } else {
      const parent = itemMap.get(item.parentId)
      if (parent) {
        parent[childrenKey].value.push(currentItem)
      }
    }
  })

  return root
}

export const addItemAtPosition = (
  tree: Value[],
  childrenKey: string,
  parentId: UniqueIdentifier | null,
  newItem: Value,
  position: number
): Value[] => {
  if (parentId === null) {
    const newTree = [...tree]
    newTree.splice(position, 0, newItem)
    return newTree
  }

  return tree.map((node) => {
    const children = node[childrenKey]?.value || []
    if (node.id === parentId) {
      const updatedChildren = [...children]
      updatedChildren.splice(position, 0, newItem)
      return {
        ...node,
        [childrenKey]: { value: updatedChildren },
      }
    }
    if (children.length) {
      return {
        ...node,
        [childrenKey]: {
          value: addItemAtPosition(children, childrenKey, parentId, newItem, position),
        },
      }
    }
    return node
  })
}

export const removeAndReturnItem = (
  tree: Value[],
  childrenKey: string,
  id: UniqueIdentifier
): { updatedTree: Value[]; item: Value | null } => {
  let itemToMove: Value | null = null

  const updatedTree = tree
    .filter((node) => {
      if (node.id === id) {
        itemToMove = node
        return false
      }
      return true
    })
    .map((node) => {
      const children = node[childrenKey]?.value || []
      if (children.length) {
        const { updatedTree: updatedChildren, item } = removeAndReturnItem(
          children,
          childrenKey,
          id
        )
        if (item) itemToMove = item
        return {
          ...node,
          [childrenKey]: { value: updatedChildren },
        }
      }
      return node
    })

  return { updatedTree, item: itemToMove }
}

export const moveItemToPosition = (
  tree: Value[],
  childrenKey: string,
  itemId: UniqueIdentifier,
  newParentId: UniqueIdentifier | null,
  position: number
): Value[] => {
  const { updatedTree, item } = removeAndReturnItem(tree, childrenKey, itemId)
  if (!item) return tree
  return addItemAtPosition(updatedTree, childrenKey, newParentId, item, position)
}

export const getChildrenCount = (flattened: FlattenValue[], itemId: UniqueIdentifier): number => {
  const item = flattened.find((item) => item.id === itemId)
  if (!item) return 0

  const countChildren = (children: FlattenValue[]): number => {
    return children.reduce((count, child) => {
      return count + 1 + countChildren(flattened.filter((i) => i.parentId === child.id))
    }, 0)
  }

  return countChildren(flattened.filter((i) => i.parentId === itemId))
}

export function getItemDepth(item: Value, childrenKey: string): number {
  if (
    !item[childrenKey] ||
    !Array.isArray(item[childrenKey].value) ||
    item[childrenKey].value.length === 0
  ) {
    return 0
  }

  return 1 + Math.max(...item[childrenKey].value.map((child) => getItemDepth(child, childrenKey)))
}
