import React, { forwardRef, memo, PropsWithChildren, ReactNode } from 'react'
import {
  Paper,
  Box,
  Tooltip,
  Icon,
  IconButton,
  Typography,
  makeStyles,
  Collapse,
  LinearProgress,
} from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import clsx from 'clsx'
import GroupError from '../../../group-error'
import isEqual from 'lodash.isequal'
import { useTitleController } from '../../use-title-controller'

type Props = {
  title: string
  errorsCount: number
  isOpen?: boolean
  moveTitle?: string
  expandTitle?: string
  actions?: ReactNode
  hasError?: boolean
  className?: string
  style?: React.CSSProperties
  index?: number
  //
  rootProps?: any
  handleProps: any
  isDragging?: boolean
  withSpacing?: boolean
  disabled?: boolean
  //
  onChangeOpen?: () => void
}

export const ArrayItem = memo(
  forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    (
      {
        children,
        title: titleProp,
        errorsCount,
        isOpen,
        moveTitle,
        expandTitle,
        actions,
        hasError,
        className,
        index,
        style,
        withSpacing,
        rootProps,
        handleProps,
        disabled,
        onChangeOpen,
      },
      ref
    ) => {
      const classes = useStyles()

      const { data: title, isFetching } = useTitleController(titleProp)

      return (
        <Paper
          ref={ref}
          className={clsx(classes.root, className, {
            [classes.hasError]: hasError,
            [classes.withSpacing]: withSpacing,
          })}
          style={style}
          {...rootProps}
        >
          <Box display="flex">
            <Tooltip placement="top" title={moveTitle || 'Move'}>
              <div className={clsx(classes.dragBtn, disabled && classes.disabled)} {...handleProps}>
                <Icon className="icon-dragindrop" />
              </div>
            </Tooltip>
            <Box
              display="flex"
              position="relative"
              flex={1}
              alignItems="center"
              py={1.5}
              px={2}
              pl={1}
            >
              <Box position="absolute" style={{ inset: 0 }} onClick={onChangeOpen} />
              <Tooltip placement="top" title={expandTitle || 'Expand'}>
                <IconButton size="small" disableRipple onClick={onChangeOpen} edge="start">
                  <ChevronRight style={{ transform: `rotate(${isOpen ? 90 : 0}deg)` }} />
                </IconButton>
              </Tooltip>
              <Box ml={1} display="flex" alignItems="center">
                {typeof index === 'number' && <Box className={classes.counter}>{index}</Box>}
                <Typography variant="body1" component="div" className={classes.title}>
                  {isFetching ? <LinearProgress style={{ width: 230 }} /> : title}
                </Typography>
                <GroupError count={errorsCount} />
              </Box>
              <Box ml="auto">{actions}</Box>
            </Box>
          </Box>
          <Collapse in={isOpen}>
            <Box px={3} py={2}>
              {children}
            </Box>
          </Collapse>
        </Paper>
      )
    }
  ),
  (prevProps, nextProps) => {
    if (nextProps.isDragging) return true
    return isEqual(prevProps, nextProps)
  }
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1px solid transparent',
    '&:has(.Mui-error)': {
      borderColor: theme.palette.error.main,
    },
  },
  withSpacing: {
    margin: theme.spacing(1, 0),
  },
  hasError: {
    borderColor: theme.palette.error.main,
  },
  dragBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'grab',
  },
  title: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  counter: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    height: 25,
    minWidth: 25,
    borderRadius: '50%',
    backgroundColor: '#e4e2ff',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
}))
