import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import { useQuery } from 'react-query'

export function useTitleController(titleValue: string) {
  const needRequest = titleValue.includes('/api')
  const resource = needRequest && titleValue.split('/')[2]
  const entityId = getIdFromIri(titleValue)

  return useQuery(
    [resource, entityId],
    () =>
      httpService.get<{ name: string }>(`/${resource}/${entityId}`).then((res) => res.data.name),
    {
      enabled: needRequest,
      initialData: titleValue,
    }
  )
}
