import React, { FC, forwardRef, HTMLAttributes } from 'react'
import clsx from 'clsx'
import RenderFormControls from '../../../../components/render-form-controls'
import { ArrayItem } from '../../../../components/array-item'
import { AttributeData, EavResourceType, TOptions } from 'modules/new-entity/types'
import { Button } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { UniqueIdentifier, useDndContext } from '@dnd-kit/core'
import { useStyles } from './use-styles'
import { FlattenValue } from '../../types'
import { getItemTitle } from '../../../../utils'
import { Actions } from '../../../../components/actions'
import InsertItemButton from 'modules/new-entity/components/shared/array-field/insert-item-button'

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  clone?: boolean
  collapsed?: boolean
  openedItem?: boolean
  depth: number
  disableInteraction?: boolean
  disableSelection?: boolean
  ghost?: boolean
  handleProps?: any
  indentationWidth: number
  isFirstInDepth: boolean
  isLastInDepth: boolean
  attrData: AttributeData
  name: string
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
  required?: boolean
  originalIndex: number
  originalItem: FlattenValue
  onCollapse?(): void
  onToggleItemOpen?(): void
  onRemove?(): void
  onAdd?: (index: number, parentId?: UniqueIdentifier | null) => void
  wrapperRef?(node: HTMLDivElement): void
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      collapsed,
      openedItem,
      onToggleItemOpen,
      onCollapse,
      onRemove,
      onAdd,
      style,
      wrapperRef,
      resourceType,
      selfType,
      name,
      options,
      disabled,
      originalIndex,
      originalItem,
      attrData,
      isFirstInDepth,
      isLastInDepth,
    },
    ref
  ) => {
    const classes = useStyles()

    const dndContext = useDndContext()

    const itemTitle = getItemTitle(originalItem, attrData)

    const hasChildren = Boolean(originalItem.children && originalItem.children.length)

    return (
      <div
        ref={wrapperRef}
        style={
          {
            '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        className={clsx(
          classes.wrapper,
          clone && classes.clone,
          ghost && classes.ghost,
          disableSelection && classes.disableSelection,
          disableInteraction && classes.disableInteraction,
          ghost && depth > 2 && classes.error
        )}
      >
        {!ghost && (
          <TreeNodeRelationLine
            depth={depth}
            isFirstInDepth={isFirstInDepth}
            isLastInDepth={isLastInDepth}
          />
        )}
        <div ref={ref} style={style} className={clsx(classes.treeItem, 'tree-item')}>
          {!clone && hasChildren && (
            <Button
              size="small"
              onClick={onCollapse}
              color="primary"
              variant="outlined"
              className={clsx(classes.expandButton, 'expand-button')}
            >
              {collapsed ? <Add /> : <Remove />}
            </Button>
          )}
          <ArrayItem
            title={itemTitle}
            handleProps={handleProps}
            hasError={false}
            errorsCount={0}
            isOpen={dndContext.active ? false : openedItem}
            onChangeOpen={onToggleItemOpen}
            isDragging={Boolean(dndContext.active)}
            actions={<Actions onRemove={onRemove} />}
            index={originalIndex + 1}
            disabled={disabled}
          >
            {!dndContext.active && (
              <RenderFormControls
                resourceType={resourceType}
                selfType={selfType}
                attrData={attrData}
                name={name}
                index={originalIndex}
                options={options}
                disabled={disabled}
              />
            )}
          </ArrayItem>
          <InsertItemButton
            type="widget"
            title="Add new item"
            style={{ left: indentationWidth * (depth === 0 ? 1 : depth), width: 'auto', right: 0 }}
            onClick={() => onAdd?.(originalIndex, originalItem.parentId)}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
)

type TreeNodeRelationLineProps = {
  depth: number
  isFirstInDepth?: boolean
  isLastInDepth?: boolean
}

export const TreeNodeRelationLine: FC<TreeNodeRelationLineProps> = ({
  depth,
  isFirstInDepth,
  isLastInDepth,
}) => {
  const verticalLines = Array.from({ length: depth + 1 }, (_, i) => i)

  return (
    <>
      {verticalLines.map((i) => {
        if (depth === 2 && i === 1) return null

        const offsetLeft = 11
        const isFirstItem = depth === 0 && isFirstInDepth
        const needHalfLine = (isLastInDepth && i === depth) || isFirstItem

        return (
          <div
            key={i}
            className="vertical-line"
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              left: `${i * 30 + offsetLeft}px`,
              top: isFirstItem ? '50%' : -8,
              height: `calc(${needHalfLine ? 50 : 100}% + 8px)`,
              width: '1px',
              backgroundColor: '#E0E0E0',
            }}
          />
        )
      })}
      <div
        className="horizontal-line"
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          left: `${30 * depth + 11}px`,
          top: 27,
          width: '20px',
          height: '1px',
          backgroundColor: '#E0E0E0',
        }}
      />
    </>
  )
}
