// Core
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { createBrowserHistory } from 'history'
// Components
import { RootLoader } from '../../components'
import ExpiredSessionModal from 'modules/auth/components/expired-session-modal'
// Services
import { httpService, storageService } from 'core/data'
import { sitesService } from 'modules/sites'
// Types
import {
  AppContext,
  AppUser,
  GetAdminResourcesFunc,
  PermissionsMap,
  ResourcesMap,
  TAdminResource,
  TAppContext,
  UserCanAction,
} from 'core/app'
import { AdminResourcesResponse, MeResponse } from '../../types'
import { getIdFromIri } from '../../utils'
import { AppService } from '../services'
import { GetResource } from '../types/get-resource'
import { Site } from 'modules/sites'

const transformResources = (resources: TAdminResource[]) => {
  const permissionsMapBySlug: PermissionsMap = {}
  const resourcesMapById: ResourcesMap = {}
  const resourcesMapBySlug: ResourcesMap = {}

  resources.forEach((resource) => {
    const { type, slug, permissions, iri } = resource

    if (!permissionsMapBySlug[type]) {
      permissionsMapBySlug[type] = {}
    }
    if (!resourcesMapById[type]) {
      resourcesMapById[type] = {}
    }
    if (!resourcesMapBySlug[type]) {
      resourcesMapBySlug[type] = {}
    }

    permissionsMapBySlug[type][slug] = permissions
    resourcesMapById[type][getIdFromIri(iri)] = resource
    resourcesMapBySlug[type][slug] = resource
  })

  return { permissionsMapBySlug, resourcesMapById, resourcesMapBySlug }
}

function setRootLoading(value: boolean) {
  const rootEl = document.getElementById('root')

  if (!rootEl) return

  rootEl.classList[value ? 'add' : 'remove']('isLoading')
}

export const AppProvider: FC = (props) => {
  const { children } = props
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<AppUser | null>(null)
  const [activeSite, setActiveSite] = useState<Site>(null!)

  const [isSessionExpired, setIsSessionExpired] = useState(false)

  const logoutAction = useRef<any>(null)
  const setLogoutAction = useCallback((action: any) => {
    logoutAction.current = action
  }, [])

  const history = useMemo(() => {
    return createBrowserHistory({
      basename: activeSite ? `/${activeSite.id}` : '/',
    })
  }, [activeSite])

  const [permissionsMapBySlug, setPermissionsMapBySlug] = useState<PermissionsMap>(null!)
  const [resourcesMapById, setResourcesMapById] = useState<ResourcesMap>(null!)
  const [resourcesMapBySlug, setResourcesMapBySlug] = useState<ResourcesMap>(null!)

  const logout = useCallback(
    async (needRefresh = false) => {
      if (logoutAction.current) {
        await logoutAction.current()
      }
      storageService.logout()
      queryClient.clear()
      setUser(null)
      setPermissionsMapBySlug(null!)
      history.replace('/login')
      if (needRefresh) {
        window.location.reload()
      }
    },
    [history, queryClient]
  )

  const getAdminResources = useCallback<GetAdminResourcesFunc>(
    async (params = {}) => {
      const { withLoading = false, userSites = [] } = params
      if (withLoading) setRootLoading(true)

      const activeSite = await sitesService.getActiveSite(history, userSites)
      const siteToken = activeSite.token

      setActiveSite(activeSite)

      const { data } = await httpService.get<AdminResourcesResponse>('/admin_resources', {
        headers: { 'X-SITE-ID': siteToken },
      })

      const { permissionsMapBySlug, resourcesMapById, resourcesMapBySlug } = transformResources(
        data['hydra:member']
      )

      setPermissionsMapBySlug(permissionsMapBySlug)
      setResourcesMapById(resourcesMapById)
      setResourcesMapBySlug(resourcesMapBySlug)

      if (withLoading) setRootLoading(false)

      return { activeSite }
    },
    [history]
  )

  const launchApp = useCallback(async () => {
    try {
      const meData = await httpService.get<MeResponse>('/me').then((res) => res.data)
      const { activeSite } = await getAdminResources({ userSites: meData.sites })

      const activeRole = await AppService.getActiveRole(activeSite, meData)

      setUser({
        ...meData,
        activeRole,
      })
    } catch (e) {
      logout()
      setLoading(false)
    }
  }, [logout, getAdminResources])

  useEffect(() => {
    // TODO: its quick fix, need to find better solution
    if (window.location.pathname.includes('/reset-password/')) {
      setLoading(false)
      return
    }
    launchApp().finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = useCallback(
    async (token: string, refreshToken: string) => {
      storageService.login(token, refreshToken)
      setLoading(true)
      await launchApp().finally(() => {
        setLoading(false)
      })
      history.replace('/')
    },
    [history, launchApp]
  )

  const loginExpired = useCallback(
    (token: string, refreshToken: string) => {
      storageService.login(token, refreshToken)
      queryClient.invalidateQueries({
        // TODO: its some workaround to skip revalidation for entity form, because it cause loosing values in form
        predicate: (query) => {
          if (Array.isArray(query.queryKey) && query.queryKey[0].includes('attributes-form')) {
            return false
          }
          return true
        },
      })
      setIsSessionExpired(false)
    },
    [queryClient]
  )

  const userCan: UserCanAction = useCallback(
    (slug, permission, type = 'entityType') => {
      if (!permissionsMapBySlug) return false
      if (!permissionsMapBySlug[type][slug]) return false
      if (!permission) return false

      return permissionsMapBySlug[type][`${slug}`]?.includes(permission)
    },
    [permissionsMapBySlug]
  )

  const getResource: GetResource = useCallback(
    (id: string, type = 'entityType') => {
      if (!resourcesMapById[type][id]) return null

      return resourcesMapById[type][id]
    },
    [resourcesMapById]
  )

  // TODO: try to find better solution
  httpService.appLogout = logout
  httpService.onTokenExpired = () => {
    if (!user) return
    setIsSessionExpired(true)
  }

  const actions = useMemo<TAppContext['actions']>(
    () => ({
      login,
      logout,
      userCan,
      getResource,
      launchApp,
      getAdminResources,
      setActiveSite,
      setLogoutAction,
    }),
    [login, logout, userCan, getResource, launchApp, getAdminResources, setLogoutAction]
  )

  if (loading) return <RootLoader />

  return (
    <AppContext.Provider
      value={{
        loading,
        user,
        resourcesMapBySlug,
        actions,
        history,
        activeSite,
      }}
    >
      {children}
      {isSessionExpired && <ExpiredSessionModal onSuccessLogin={loginExpired} />}
    </AppContext.Provider>
  )
}
