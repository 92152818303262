import { httpService } from 'core/data'
import { ExportProcess } from './types'

type CreateExportProcessParams = {
  entityType: string
  [key: string]: string
}

export const createExportProcess = (params: CreateExportProcessParams) => {
  return httpService
    .post<{ data: Pick<ExportProcess, 'id'> }>('/entity_exports', {
      request: { parameters: params },
    })
    .then((res) => res.data)
}

export const getExportProcess = (id: string) => {
  return httpService.get<ExportProcess>(`/entity_exports/${id}`).then((res) => res.data)
}

export const getExportData = (id: string) => {
  return httpService
    .get<Blob>(`/entity_exports/${id}/data`, {
      responseType: 'blob',
      headers: { Accept: 'text/csv' },
    })
    .then((res) => res.data)
}
