// Core
import React, { FC } from 'react'
import { Button, Icon } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { ButtonProps } from '@material-ui/core/Button/Button'
import { defaultThemeColors } from 'core/themes/default/default'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0.5, 1),
      minHeight: '35px',
      backgroundColor: defaultThemeColors.purpleLight,
      textTransform: 'none',
      color: theme.palette.primary.main,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
)

type Props = {
  title: string
} & ButtonProps

export const AddItemButton: FC<Props> = ({ title, ...buttonProps }) => {
  const classes = useStyles()

  return (
    <Button {...buttonProps} className={classes.root} fullWidth>
      <Icon className={clsx('icon-plus-circle', classes.icon)} />
      {title}
    </Button>
  )
}
