// Core
import React, { FC, useCallback, useMemo } from 'react'
// Components
import {
  CheckboxField,
  DateTimePicker,
  EditorField,
  ImageAttachment,
  MultiSelectField,
  RadioButton,
  RadioColor,
  SelectField,
  SwitcherField,
  TextField,
} from 'core/form/components'
import RelationsField from 'core/form/components/relations-field'
import { MultipleTypesReference } from '../components'
import Group from '../../group'
import Repeater from '../../repeater'
import { RepeaterTreeView } from '../../repeater/repeater-tree-view'
import SystemSlug from '../components/system-slug'
import LinkField from '../components/link-field'
import MultipleTypeReference from '../components/multiple-type-reference'
import EntityTypeReference from '../components/entity-type-reference'
import CrossSiteReference from '../components/cross-site-reference'
import SiteReference from '../components/site-reference'
import LocalizationSelect from '../components/localization-select'
// Hooks
import { useGetHierReference } from 'modules/new-entity/hooks'
import { useSetEntityTitle } from '../hooks/use-set-entity-title'
import useIsRequired from '../hooks/use-is-required'
// Utils
import { withDotValue } from 'core/utils'
import { coloredRadio } from './coloredRadio'
// Types
import { AttributeData, IEntityWidget, MutationType, TOptions } from 'modules/new-entity/types'
import { AttributeTypesList } from 'common/types'
import { enumToOptions } from './enums-to-options'
import { EavResourceType } from 'modules/new-entity/types'
import { IEntity } from 'modules/new-entity/types'

enum RenderTypes {
  string = 'string',
  string_l10n = 'string_l10n',
  text_editor = 'text_editor',
  text_editor_l10n = 'text_editor_l10n',
  text = 'text',
  text_l10n = 'text_l10n',
  boolean = 'boolean',
  date_time = 'date_time',
  number = 'number',
  select = 'select',
  smart_select = 'smart_select',
  multiple_reference = 'multiple_reference',
  hierarchical_reference = 'hierarchical_reference',
  ref_et_collection = 'ref_et_collection',
  file = 'file',
  repeater = 'repeater',
  group = 'group',
  multiple_types_reference = 'multiple_types_reference',
  site_reference = 'site_reference',
}

const controlsMap: { [controlType: string]: string[] } = {
  [RenderTypes.string]: [AttributeTypesList.slug, AttributeTypesList.string],
  [RenderTypes.string_l10n]: [AttributeTypesList.string_l10n],
  [RenderTypes.text_editor]: [AttributeTypesList.text_editor],
  [RenderTypes.text_editor_l10n]: [AttributeTypesList.text_editor_l10n],
  [RenderTypes.text]: [AttributeTypesList.text],
  [RenderTypes.text_l10n]: [AttributeTypesList.text_l10n],
  [RenderTypes.boolean]: [AttributeTypesList.boolean],
  [RenderTypes.date_time]: [AttributeTypesList.date_time],
  [RenderTypes.number]: [AttributeTypesList.integer, AttributeTypesList.decimal],
  [RenderTypes.select]: [AttributeTypesList.select, AttributeTypesList.multi_select],
  [RenderTypes.smart_select]: [
    AttributeTypesList.reference_many_to_one,
    AttributeTypesList.reference_many_to_many,
    AttributeTypesList.reference_one_to_one,
    AttributeTypesList.reference_many_to_many_multiple_types_reversed,
  ],
  [RenderTypes.multiple_reference]: [
    AttributeTypesList.reference_many_to_one_multiple_types,
    AttributeTypesList.reference_many_to_one_hierarchical,
  ],
  [RenderTypes.ref_et_collection]: [AttributeTypesList.reference_many_to_one_multiple_entity_types],
  [RenderTypes.file]: [AttributeTypesList.image, AttributeTypesList.attachment],
  [RenderTypes.repeater]: [AttributeTypesList.repeater],
  [RenderTypes.group]: [AttributeTypesList.group],
  [RenderTypes.multiple_types_reference]: [
    AttributeTypesList.reference_many_to_many_multiple_types,
  ],
  [RenderTypes.site_reference]: [AttributeTypesList.reference_many_to_one_site],
}

type TControlRenderer = {
  attributeData: AttributeData
  name: string
  options: TOptions
  nested?: boolean
  selfType: 'entity' | 'widget'
  type?: MutationType
  resourceType: EavResourceType
  disabled?: boolean
  entity?: IEntity
  widget?: IEntityWidget
  slugs?: any
  isNamable?: boolean
}

const ControlRenderer: FC<TControlRenderer> = (props) => {
  const {
    attributeData,
    name: initialName,
    options,
    nested,
    selfType,
    resourceType,
    disabled,
    entity,
    widget,
    slugs,
    isNamable,
  } = props

  const systemTitleOnBlur = useSetEntityTitle(slugs?.system)
  const cloakedUrlOnBlur = useSetEntityTitle(slugs?.cloaked)
  /** use name with .value wrapper */
  const name = useMemo(() => withDotValue(initialName), [initialName])
  const { entityUrl } = useGetHierReference(entity)
  const isRequired = useIsRequired(attributeData)

  const controlType: string = useMemo(() => {
    const getControlType = (type: string) => {
      let returnValue = ''
      for (const controlType in controlsMap) {
        if (controlsMap[controlType].indexOf(type) >= 0) {
          returnValue = controlType
          break
        }
      }
      return returnValue
    }
    return getControlType(attributeData.attributeType.type)
  }, [attributeData.attributeType.type])

  const isBlur = useMemo(() => {
    return slugs?.cloaked || slugs?.system
  }, [slugs])

  const titleBlurHandler = useCallback(
    (e) => {
      systemTitleOnBlur?.(e)
      cloakedUrlOnBlur?.(e)
    },
    [cloakedUrlOnBlur, systemTitleOnBlur]
  )

  const referenceChangeHandler = useCallback(
    (e) => {
      if (selfType === 'widget' && attributeData.slug === 'global_widget_collection') {
        const event = new CustomEvent('collection_change', {
          detail: {
            widgetId: widget?.id,
            id: e,
          },
        })
        document.dispatchEvent(event)
      }
    },
    [attributeData.slug, selfType, widget?.id]
  )

  switch (controlType) {
    case RenderTypes.string: {
      if (
        (attributeData.slug === 'slug' || attributeData.slug === 'cloaked_url') &&
        !nested &&
        selfType !== 'widget'
      ) {
        return (
          <SystemSlug
            name={name}
            entityUrl={entityUrl}
            data={attributeData}
            entityLabel={entity?.originalLabel}
            disabled={disabled}
          />
        )
      }

      if (attributeData.options.show_links_modal) {
        return (
          <LinkField
            disabled={disabled}
            label={attributeData.name}
            name={name}
            required={isRequired}
          />
        )
      }

      if (attributeData.slug === 'localization') {
        return <LocalizationSelect name={name} label={attributeData.name} disabled={disabled} />
      }

      return (
        <TextField
          onBlur={isBlur && isNamable ? titleBlurHandler : undefined}
          name={name}
          label={attributeData.name}
          placeholder={attributeData.name}
          disabled={disabled}
          required={isRequired}
        />
      )
    }
    case RenderTypes.string_l10n: {
      return (
        <TextField
          name={name}
          label={attributeData.name}
          placeholder={attributeData.name}
          onBlur={isBlur && isNamable ? titleBlurHandler : undefined}
          localized
          disabled={disabled}
          required={isRequired}
        />
      )
    }
    case RenderTypes.text:
      return (
        <TextField
          name={name}
          label={attributeData.name}
          placeholder={attributeData.name}
          multiline
          rows={6}
          disabled={disabled}
          required={isRequired}
        />
      )
    case RenderTypes.text_l10n:
      return (
        <TextField
          name={name}
          label={attributeData.name}
          placeholder={attributeData.name}
          multiline
          rows={6}
          localized
          disabled={disabled}
          required={isRequired}
        />
      )
    case RenderTypes.boolean: {
      if (attributeData.options.type === 'switch') {
        return <SwitcherField disabled={disabled} name={name} label={attributeData.name} />
      }
      /** By default */
      return <CheckboxField disabled={disabled} name={name} label={attributeData.name} />
    }
    case RenderTypes.date_time: {
      const readonly = attributeData.slug === 'updated_at'
      const system = attributeData.slug === 'updated_at' || attributeData.slug === 'created_at'

      if (resourceType === EavResourceType.TEMPLATE && (readonly || system)) return null

      return (
        <DateTimePicker
          slug={attributeData.slug}
          system={system}
          readonly={readonly}
          name={name}
          label={attributeData.name}
          disabled={disabled}
          required={isRequired}
          withTimezone={['match_date_time'].includes(attributeData.slug)}
        />
      )
    }
    case RenderTypes.number: {
      return (
        <TextField
          name={name}
          label={attributeData.name}
          placeholder={attributeData.name}
          type="number"
          disabled={disabled}
          required={isRequired}
        />
      )
    }
    case RenderTypes.select: {
      const isMulti = attributeData.attributeType.type === AttributeTypesList.multi_select
      const enums = attributeData.attributeEnums || []
      const options = enumToOptions(enums)

      if (attributeData.slug === 'status') {
        // Commented according task VUL-7770
        return null
        // return <EntityStatus attrData={attributeData} name={name} options={options} />
      }
      if (coloredRadio(attributeData.slug)) {
        return (
          <RadioColor
            options={options}
            name={name}
            label={attributeData.name}
            attrSlug={attributeData.slug}
            disabled={disabled}
          />
        )
      }
      if (attributeData.options.type === 'radio_button') {
        return (
          <RadioButton
            disabled={disabled}
            options={options}
            name={name}
            label={attributeData.name}
            required={isRequired}
          />
        )
      }
      if (isMulti) {
        return (
          <MultiSelectField
            disabled={disabled}
            name={name}
            label={attributeData.name}
            options={options}
            required={isRequired}
          />
        )
      }
      return (
        <SelectField
          disabled={disabled}
          name={name}
          label={attributeData.name}
          options={options}
          multiple={false}
          required={isRequired}
        />
      )
    }
    case RenderTypes.smart_select: {
      const {
        entityType,
        attributeType: { type },
      } = attributeData

      const isMulti = type === AttributeTypesList.reference_many_to_many
      const entityTypeIri = entityType

      return (
        <RelationsField
          label={attributeData.name}
          valueField="@id"
          name={name}
          source="entities"
          reqParams={{ entityType }}
          multiple={isMulti}
          // @ts-ignore
          entityTypeIri={entityTypeIri}
          disabled={disabled}
          onChange={referenceChangeHandler}
          required={isRequired}
        />
      )
    }
    case RenderTypes.text_editor: {
      return (
        <EditorField
          disabled={disabled}
          label={attributeData.name}
          name={name}
          required={isRequired}
        />
      )
    }
    case RenderTypes.text_editor_l10n: {
      return (
        <EditorField
          disabled={disabled}
          localized
          label={attributeData.name}
          name={name}
          required={isRequired}
        />
      )
    }
    case RenderTypes.multiple_reference: {
      return (
        <MultipleTypeReference
          name={name}
          data={attributeData}
          disabled={disabled}
          required={isRequired}
        />
      )
    }
    case RenderTypes.ref_et_collection: {
      return (
        <EntityTypeReference
          name={name}
          data={attributeData}
          disabled={disabled}
          required={isRequired}
        />
      )
    }
    case RenderTypes.file: {
      const {
        attributeType: { type },
      } = attributeData
      return (
        <ImageAttachment
          disabled={disabled}
          label={attributeData.name}
          type={type}
          name={name}
          slug={attributeData.slug}
          required={isRequired}
        />
      )
    }
    /**
     * Repeater & Group
     * */
    case RenderTypes.repeater: {
      if (attributeData.slug === 'menu_items') {
        return (
          <RepeaterTreeView
            resourceType={resourceType}
            disabled={disabled}
            selfType={selfType}
            name={name}
            attrData={attributeData}
            options={options}
            required={isRequired}
          />
        )
      }

      return (
        <Repeater
          resourceType={resourceType}
          disabled={disabled}
          selfType={selfType}
          name={name}
          data={attributeData}
          options={options}
          required={isRequired}
        />
      )
    }
    case RenderTypes.group: {
      return (
        <Group
          resourceType={resourceType}
          disabled={disabled}
          selfType={selfType}
          data={attributeData}
          name={name}
          options={options}
          required={isRequired}
        />
      )
    }
    case RenderTypes.multiple_types_reference: {
      if (attributeData.slug === 'alternate_pages') {
        return <CrossSiteReference attributeData={attributeData} name={name} disabled={disabled} />
      }

      return (
        <MultipleTypesReference attributeData={attributeData} name={name} disabled={disabled} />
      )
    }
    case RenderTypes.site_reference: {
      return <SiteReference attributeData={attributeData} name={name} disabled={disabled} />
    }
  }

  return null
}

export default ControlRenderer
