import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import {
  MultipleLayoutField,
  TextField,
  CheckboxField,
  ImageAttachment,
} from 'core/form/components'
import { FormHolder } from 'ui'
import { useTranslation } from 'react-i18next'
import { useTypeRosourceContext } from '../../hooks'
import { useGetLayouts } from 'common/hooks'
import { TypeOptions } from 'modules/types-crud/components'

const CrudForm: FC = () => {
  const { t } = useTranslation()
  const context = useTypeRosourceContext()
  const resourceName = context?.options.resourceName
  const { data } = useGetLayouts()

  return (
    <FormHolder title={t(`resource.${resourceName}`)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField localized name={'name'} label={t('labels.name')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name={'name'} label={t('labels.internal')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name={'slug'} label={t('labels.slug')} />
        </Grid>
        {resourceName === 'entity_types' && (
          <>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.urlable')} name="urlable" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.enable-builder')} name="builder" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.favorite')} name="favorite" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.single')} name="single" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.global')} name="global" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label={t('labels.shared-network')} name="sharedBetweenNetworkSites" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label="Enable export csv" name="options.enable_export_csv" />
            </Grid>
            <Grid item xs={12}>
              <CheckboxField label="Enable import csv" name="options.enable_import_csv" />
            </Grid>
            <TypeOptions />
            <Grid item xs={12}>
              <MultipleLayoutField data={data} />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline name={'actions'} label={t('labels.actions')} minRows={2} />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ImageAttachment name="icon" type="icon" slug="icon" />
        </Grid>
      </Grid>
    </FormHolder>
  )
}

export default CrudForm
