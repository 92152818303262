export const coloredRadio = (attrSlug: string) => {
  if (
    attrSlug === 'sticky_cta_west_color' ||
    attrSlug === 'sticky_cta_east_color' ||
    attrSlug === 'color' ||
    attrSlug === 'configuration' ||
    attrSlug === 'button_color' ||
    attrSlug === 'home_ctas_color' ||
    attrSlug === 'colored_border' ||
    attrSlug === 'ggs_label_color' ||
    attrSlug === 'label_color'
  ) {
    return true
  }

  return false
}
