import React, { FC, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { Collapse } from 'ui'
import { booleanVisualisation, selectVisualisation } from './utils'
import { Select } from './components'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { CheckboxField } from 'core/form'
import { reference } from 'common/types'

type TProps = {
  data?: object
}

const OptionsFields: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const [bools, selects] = useMemo(() => {
    return [booleanVisualisation(t), selectVisualisation(t)] as const
  }, [t])

  const { values } = useFormikContext<any>()

  return (
    <>
      <Collapse isOpen={true} label={t('attrs.options')}>
        <Grid container spacing={3}>
          {(() => {
            switch (values.type) {
              case 'boolean': {
                return (
                  <Grid item xs={6}>
                    <Select defaultValue={'checkbox'} data={bools} name={'options.type'} />
                  </Grid>
                )
              }

              case 'select': {
                return (
                  <Grid item xs={6}>
                    <Select defaultValue={'select'} data={selects} name={'options.type'} />
                  </Grid>
                )
              }

              case 'string': {
                return (
                  <Grid item xs={6}>
                    <CheckboxField
                      label={t('attrs.show-links-modal')}
                      name={'options.show_links_modal'}
                    />
                  </Grid>
                )
              }

              case 'repeater': {
                return (
                  <Grid item xs={6}>
                    <CheckboxField label="Enable tree view" name={'options.enable_tree_view'} />
                  </Grid>
                )
              }

              default: {
                return null
              }
            }
          })()}

          <Grid item xs={6}>
            <CheckboxField label={t('attrs.hide-attr')} name={'options.hidden'} />
          </Grid>

          <Grid item xs={6}>
            <CheckboxField label={t('attrs.show-in-sidebar')} name={'options.show_in_sidebar'} />
          </Grid>

          <Grid item xs={6}>
            <CheckboxField label={t('attrs.preserve')} name={'options.preservation'} />
          </Grid>

          <Grid item xs={6}>
            <CheckboxField label={t('attrs.skip_from_copy')} name={'options.skip_from_copy'} />
          </Grid>

          <Grid item xs={6}>
            <CheckboxField label={t('attrs.readonly')} name={'readonly'} />
          </Grid>

          {reference.includes(values.type) && (
            <Grid item xs={6}>
              <CheckboxField
                label={t('attrs.create-entity')}
                name={'options.create_entity_button'}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  )
}

export default OptionsFields
