// Core
import React, { FC, memo, useCallback, useState } from 'react'
import { FieldArray, FormikProps, getIn, isString } from 'formik'
import { Box } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
// Components
import { RenderFormControls } from './components'
import { GroupHeader } from '../group-header'
import GroupWrapper from '../group-wrapper'
import { Actions } from './components/actions'
import { ArrayItem } from './components/array-item'
import { AddItemButton } from 'modules/new-entity/components/shared/array-field/add-item-button'
import InsertItemButton from 'modules/new-entity/components/shared/array-field/insert-item-button'
import { NoItems } from 'modules/new-entity/components/shared/array-field/no-items'
// Hooks
import useGroupErrors from '../../hooks/use-group-errors'
import { useSitesContext } from 'modules/sites'
// Utils
import { DefaultValuesGenerator } from 'modules/new-entity/transformers'
// Types
import { AttributeData, EavResourceType, TOptions } from 'modules/new-entity/types'
import { getErrorsCount } from '../../utils'

type TProps = {
  data: AttributeData
  name: string
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
  required?: boolean
}

const Repeater: FC<TProps> = (props) => {
  const { data, name, options, selfType, disabled, resourceType, required } = props
  const { locales } = useSitesContext()
  const localizations = locales.site

  const { hasError, errorsCount } = useGroupErrors(name)

  const disablePushInsert = useCallback(
    (values: any[]) => {
      if (!values || !data.validationRules.count?.max) return false
      return values.length >= data.validationRules.count?.max
    },
    [data.validationRules.count?.max]
  )

  const canShowError = useCallback(
    (form: FormikProps<any>) => {
      return form.errors && isString(getIn(form.errors, name)) && hasError
    },
    [hasError, name]
  )

  const [openedItems, setOpenedItems] = useState<string[]>([])

  const openHandler = useCallback((key: string) => {
    setOpenedItems((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key)
      }
      return [...prev, key]
    })
  }, [])

  return (
    <FieldArray
      name={name}
      render={(params) => {
        const { form, insert, push, remove, move } = params
        const values: any[] = getIn(form.values, name)
        const errors: any[] = getIn(form.errors, name) || []

        const expandCollapseAllHandler = () => {
          const hasOpened = openedItems.length !== 0

          if (hasOpened) {
            setOpenedItems([])
          } else {
            const keysToOpen = values.map((item) => item.key)
            setOpenedItems(keysToOpen)
          }
        }

        const addNewItemHandler = (index?: number) => {
          const newItem = new DefaultValuesGenerator(
            data.setAttributes || [],
            localizations
          ).getValues()
          if (typeof index !== 'undefined') {
            insert(index, newItem)
          } else {
            push(newItem)
          }
          openHandler(newItem.key)
        }

        return (
          <GroupWrapper hasError={hasError}>
            <GroupHeader
              title={data.name}
              expanded={openedItems.length !== 0}
              required={required}
              onExpandCollapse={expandCollapseAllHandler}
              errorsCount={errorsCount}
            />
            <Box mt={1} />
            <DragDropContext
              onDragEnd={(event) => {
                const sourceIndex = event.source.index
                const destinationIndex = event.destination?.index
                if (typeof destinationIndex === 'undefined' || destinationIndex === sourceIndex) {
                  return
                }
                move(sourceIndex, destinationIndex)
              }}
            >
              <Droppable droppableId="items">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {values.map((item, index) => (
                      <Box position="relative" key={item.key}>
                        <Draggable draggableId={item.key} index={index}>
                          {(draggableProvided) => (
                            <ArrayItem
                              ref={draggableProvided.innerRef}
                              rootProps={draggableProvided.draggableProps}
                              handleProps={draggableProvided.dragHandleProps}
                              title={getItemTitle(values[index], data)}
                              errorsCount={hasError ? getErrorsCount(errors[index]) : 0}
                              isOpen={openedItems.includes(item.key)}
                              withSpacing
                              disabled={disabled}
                              index={index + 1}
                              actions={
                                <Actions
                                  canMoveUp={index !== 0}
                                  canMoveDown={index !== values.length - 1}
                                  onRemove={() => remove(index)}
                                  onMoveUp={() => move(index, index - 1)}
                                  onMoveDown={() => move(index, index + 1)}
                                />
                              }
                              onChangeOpen={() => openHandler(item.key)}
                            >
                              <RenderFormControls
                                resourceType={resourceType}
                                selfType={selfType}
                                attrData={data}
                                name={name}
                                index={index}
                                options={options}
                                disabled={disabled}
                              />
                            </ArrayItem>
                          )}
                        </Draggable>
                        <InsertItemButton
                          type="widget"
                          title="Add new item"
                          onClick={() => addNewItemHandler(index)}
                          disabled={disabled}
                        />
                      </Box>
                    ))}
                    {provided.placeholder}
                    {values.length === 0 && <NoItems />}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {!disabled && (
              <AddItemButton
                title="Add item"
                disabled={disablePushInsert(values)}
                onClick={() => addNewItemHandler()}
              />
            )}
            {canShowError(form) && <div style={{ color: 'red' }}>{getIn(form.errors, name)}</div>}
          </GroupWrapper>
        )
      }}
    />
  )
}

function getItemTitle(values: Record<string, { value: string }>, attributeData: AttributeData) {
  const noTitle = 'Untitled'
  const findNamableAttr = attributeData.setAttributes?.find((item) => item.nameable === true)
  if (!findNamableAttr) return noTitle

  return values[findNamableAttr.attribute['@id']].value || noTitle
}

export default memo(Repeater)
