// Core
import React, { FC } from 'react'
import * as Yup from 'yup'
// Components
import { Edit } from 'core/data'
import CrudForm from '../crud-form'
// Hooks
import { useActions } from 'modules/user/hooks/use-actions'
// Types
import { ResourceProps } from 'core/data'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  alias: Yup.string().required(),
})

const UserEdit: FC<ResourceProps> = (props) => {
  const { actionsData } = useActions(props.name)

  return (
    <Edit
      validationSchema={validationSchema}
      crudForm={CrudForm}
      actionsData={actionsData}
      {...props}
    />
  )
}

export default UserEdit
