import React, { FC, ReactNode } from 'react'
import { Box, FormLabel, IconButton, makeStyles } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import GroupError from '../group-error'

type Props = {
  title: ReactNode
  expanded: boolean
  required?: boolean
  errorsCount?: number
  onExpandCollapse: () => void
}

export const GroupHeader: FC<Props> = ({
  title,
  expanded,
  required,
  onExpandCollapse,
  errorsCount,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center">
      <IconButton size="small" edge="start" onClick={onExpandCollapse}>
        <ChevronRight style={{ transform: `rotate(${expanded ? 90 : 0}deg)` }} />
      </IconButton>
      <Box ml={1} display="flex" alignItems="center">
        <FormLabel
          className={classes.label}
          required={required}
          error={!!errorsCount && errorsCount > 0}
        >
          {title}
        </FormLabel>
        <Box ml={1}>
          <GroupError count={errorsCount || 0} />
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
  },
}))
