import React, { FC, useState } from 'react'
import { AttributeData, EavResourceType, TOptions } from 'modules/new-entity/types'
import { SortableTree } from './sortable-tree'
import GroupWrapper from '../../group-wrapper'
import { GroupHeader } from '../../group-header'
import { Box } from '@material-ui/core'
import { UniqueIdentifier } from '@dnd-kit/core'
import { Value } from './sortable-tree/types'
import { useField } from 'formik'
import useGroupErrors from '../../../hooks/use-group-errors'

type Props = {
  attrData: AttributeData
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  name: string
  disabled?: boolean
  required?: boolean
}

export const RepeaterTreeView: FC<Props> = ({
  attrData,
  name,
  options,
  disabled,
  selfType,
  resourceType,
  required,
}) => {
  const { hasError, errorsCount } = useGroupErrors(name)
  const [field, , helpers] = useField<Value[]>(name)
  const [openedItems, setOpenedItems] = useState<UniqueIdentifier[]>([])
  const [collapsedItems, setCollapsedItems] = useState<UniqueIdentifier[]>([])

  const expandCollapseAllHandler = () => {
    const hasOpened = collapsedItems.length !== 0

    if (hasOpened) {
      setCollapsedItems([])
    } else {
      const keysToOpen = field.value.map((item) => item.id)
      setCollapsedItems(keysToOpen)
    }
  }

  return (
    <GroupWrapper hasError={hasError}>
      <GroupHeader
        title={attrData.name}
        expanded={collapsedItems.length !== 0}
        required={required}
        errorsCount={errorsCount}
        onExpandCollapse={expandCollapseAllHandler}
      />
      <Box mt={1} />
      <SortableTree
        value={field.value || []}
        onChange={helpers.setValue}
        name={name}
        attrData={attrData}
        options={options}
        disabled={disabled}
        selfType={selfType}
        resourceType={resourceType}
        openedItems={openedItems}
        collapsedItems={collapsedItems}
        setCollapsedItems={setCollapsedItems}
        setOpenedItems={setOpenedItems}
      />
    </GroupWrapper>
  )
}
