import React, { FC, useState } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { EntityType } from 'core/types'
import { UploadFiles } from './upload-file'
import { useExportEntities } from './export'

type Props = {
  entityType: EntityType
}

const getOption = (field: string, entityType: EntityType) => {
  if (Array.isArray(entityType.options)) {
    return null
  }
  return entityType.options[field]
}

export const BulkActionsButton: FC<Props> = ({ entityType }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const canExport = getOption('enable_export_csv', entityType)
  const canImport = getOption('enable_import_csv', entityType)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [importOpen, setImportOpen] = useState(false)

  const { startExport, isLoading: exportLoading } = useExportEntities({ entityType })

  if (!canExport && !canImport) return null

  return (
    <>
      <Box ml={1}>
        <IconButton onClick={handleClick} size="small">
          <MoreHoriz />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {canExport && (
            <MenuItem button disabled={exportLoading} onClick={() => startExport()}>
              Export csv
            </MenuItem>
          )}
          {canImport && (
            <MenuItem button disabled={exportLoading} onClick={() => setImportOpen(true)}>
              Import csv
            </MenuItem>
          )}
        </Menu>
      </Box>
      {importOpen && (
        <UploadFiles
          entityTypeIri={entityType['@id']}
          isOpen={importOpen}
          onClose={() => setImportOpen(false)}
        />
      )}
    </>
  )
}
