// Core
import React, { FC, useRef } from 'react'
import { Icon, IconButton, ListItemIcon, MenuItem, Popover, PopoverOrigin } from '@material-ui/core'
// Types
import { Actions } from 'core/data/types/actions'

type BaseProps = {
  actions: Actions
  showActions: boolean
  onShowActions: (value: boolean) => void
  loading: boolean
  disabled?: boolean
  btnClassName?: string
  popoverClassName?: string
  aqaAction?: string
  anchorOriginVertical?: PopoverOrigin['vertical']
  anchorOriginHorizontal?: PopoverOrigin['horizontal']
  transformOriginVertical?: PopoverOrigin['vertical']
  transformOriginHorizontal?: PopoverOrigin['horizontal']
}

type PropsWithAnchorEl = BaseProps & {
  anchorEl: HTMLDivElement | null
  isBtnRef?: never
}

type PropsWithIsBtnRef = BaseProps & {
  anchorEl?: null
  isBtnRef: boolean
}

type Props = PropsWithAnchorEl | PropsWithIsBtnRef

const ActionsPopover: FC<Props> = (props) => {
  const {
    actions,
    showActions,
    onShowActions,
    loading,
    disabled,
    anchorEl,
    isBtnRef,
    btnClassName,
    popoverClassName,
    aqaAction,
    anchorOriginVertical = 'bottom',
    anchorOriginHorizontal = 'right',
    transformOriginVertical = 'top',
    transformOriginHorizontal = 'right',
  } = props

  const ref = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <IconButton
        size="small"
        disabled={loading || disabled}
        onClick={() => onShowActions(true)}
        data-aqa-action={aqaAction}
        {...(btnClassName
          ? { className: btnClassName }
          : { style: { borderRadius: 3, marginLeft: 5 } })}
        {...(isBtnRef ? { ref } : {})}
      >
        <Icon className="icon-more_horiz-1" />
      </IconButton>
      <Popover
        anchorEl={isBtnRef ? ref.current : anchorEl}
        open={showActions}
        onClose={() => onShowActions(false)}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
        {...(popoverClassName ? { className: popoverClassName } : {})}
      >
        {actions.map((action) => {
          if (!action.condition) return null

          const iconEl = !action.icon ? null : typeof action.icon === 'string' ? (
            <Icon className={action.icon} />
          ) : (
            action.icon
          )

          return (
            <MenuItem
              key={action.name}
              data-aqa-action={action.name}
              onClick={(e) => {
                e.stopPropagation()
                action.action(onShowActions)
                onShowActions(false)
              }}
            >
              <ListItemIcon>{iconEl}</ListItemIcon>
              {action.label}
            </MenuItem>
          )
        })}
      </Popover>
    </>
  )
}

export default ActionsPopover
