// Core
import React, { FC } from 'react'
import { IconButton, TableCell, Icon, CircularProgress, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { History } from '@material-ui/icons'
// Components
import { ActionsCellWrapper } from 'ui/data-grid/actions-cell-wrapper'
// Hooks
import { useDataViewContext } from './data-view-context'
import {
  useChangeEntityStatus,
  useDeleteEntity,
  useTypePermissions,
} from 'modules/new-entity/hooks'
// Types
import { RowData } from './types'

type Props = {
  rowData: RowData
  children?: never
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    left: '0',
  },
  column: {
    whiteSpace: 'nowrap',
  },
}))

const ActionsColumn: FC<Props> = (props) => {
  const classes = useStyles()
  const { rowData } = props
  const { dataQKey, typeId, entityType, refetch, isDataFetching } = useDataViewContext()
  const editPath = `/entities/${typeId}/edit/${rowData.id}`
  // @ts-ignore
  const permissions = useTypePermissions(entityType)
  const isArchived = rowData.status === 'archive'

  const { deleteEntity, deleteEntityM } = useDeleteEntity(rowData.id, {
    invalidateKey: dataQKey,
    isGlobalEntity: entityType.global,
  })

  const { changeEntityStatus, changeStatusM } = useChangeEntityStatus(rowData.id, rowData.status, {
    onSuccess: () => {
      refetch()
    },
  })

  const canDeleteArchived = ['architect', 'admin'].includes(permissions.activeRole)
  const canDelete = isArchived ? canDeleteArchived : permissions.canDelete
  const isLoading = deleteEntityM.isLoading || changeStatusM.isLoading
  const actionsDisabled = isDataFetching

  return (
    <TableCell className={classes.column}>
      <ActionsCellWrapper>
        {canDelete && (
          <IconButton
            className={classes.iconButton}
            onClick={deleteEntity}
            disabled={isLoading || actionsDisabled}
            edge="start"
            size="small"
          >
            <Icon className="icon-delete" />
            {isLoading && <CircularProgress className={classes.loader} size={16} />}
          </IconButton>
        )}
        {!isArchived && permissions.canEdit && (
          <IconButton
            component={NavLink}
            to={editPath}
            edge="end"
            disabled={isLoading || actionsDisabled}
            size="small"
          >
            <Icon className="icon-edit" />
          </IconButton>
        )}
        {isArchived && (
          <IconButton
            onClick={() => changeEntityStatus('draft')}
            edge="end"
            disabled={isLoading || actionsDisabled}
            size="small"
          >
            <History />
          </IconButton>
        )}
      </ActionsCellWrapper>
    </TableCell>
  )
}

export default ActionsColumn
