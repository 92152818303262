import { AttributeData } from 'modules/new-entity/types'

export function getItemTitle(
  values: Record<string, { value: string }>,
  attributeData: AttributeData
) {
  const noTitle = 'Untitled'
  const findNamableAttr = attributeData.setAttributes?.find((item) => item.nameable === true)
  if (!findNamableAttr) return noTitle

  return values[findNamableAttr.attribute['@id']].value || noTitle
}
