// Core
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, UseMutationResult } from 'react-query'
// Icons
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined'
// Hooks
import { useNotify } from 'core/hooks'
// Services
import { httpService } from 'core/data'

export const useActions = (name: string) => {
  const notify = useNotify()
  const { t } = useTranslation()
  const { id } = useParams()

  const generateOptions = (action: 'reset' | 'enable' | 'disable') => ({
    onSuccess: () => {
      notify(t(`user.mfa-${action}-success`), { type: 'success' })
    },
    onError: () => {
      notify(t(`user.mfa-${action}-error`), { type: 'error' })
    },
  })

  const resetMfa = useMutation(
    () => httpService.post(`/${name}/${id}/reset_2fa`),
    generateOptions('reset')
  )

  const enableMfa = useMutation(
    () => httpService.put(`/${name}/${id}/enable_2fa`),
    generateOptions('enable')
  )

  const disableMfa = useMutation(
    () => httpService.post(`/${name}/${id}/disable_2fa`),
    generateOptions('disable')
  )

  const actions = useCallback(
    ({ data, refetch }) => {
      const actionWrapper =
        (mutation: UseMutationResult<unknown, unknown, void, unknown>, shouldRefetch: boolean) =>
        (onShowActions?: (value: boolean) => void) => {
          mutation.mutate(undefined, { onSuccess: () => shouldRefetch && refetch() })
          onShowActions && onShowActions(false)
        }

      const { twoFactorDisabled } = data

      return [
        {
          name: 'reset_mfa',
          label: t('user.mfa-reset-label'),
          action: actionWrapper(resetMfa, false),
          condition: !twoFactorDisabled,
          icon: <LockOpenOutlinedIcon />,
        },
        {
          name: 'enable_mfa',
          label: t('user.mfa-enable-label'),
          action: actionWrapper(enableMfa, true),
          condition: twoFactorDisabled,
          icon: <LockOutlinedIcon />,
        },
        {
          name: 'disable_mfa',
          label: t('user.mfa-disable-label'),
          action: actionWrapper(disableMfa, true),
          condition: !twoFactorDisabled,
          icon: <NoEncryptionOutlinedIcon />,
        },
      ]
    },
    [disableMfa, enableMfa, resetMfa, t]
  )

  const actionsData = {
    actions,
    loading: disableMfa.isLoading || enableMfa.isLoading || resetMfa.isLoading,
  }

  return { actionsData }
}
