import { useNotify } from 'core/hooks'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { createExportProcess, getExportData, getExportProcess } from './api'
import { slugifyValue } from 'modules/new-entity/utils'
import { useGetActiveSite } from 'modules/sites'
import { EntityType } from 'core/types'
import { runBlobDownload } from './utils'
import { useEvents } from 'core/events'
import { parseEvent } from 'core/events/utils'
import { ExportProcess } from './types'

type ActiveExportState = {
  exportId: string | null
  toastId: string | null
}

export const useExportEntities = ({ entityType }: { entityType: EntityType }) => {
  const notify = useNotify()
  const activeSite = useGetActiveSite()
  const { subscribe } = useEvents()

  const [activeExport, setActiveExport] = useState<ActiveExportState>({
    exportId: null,
    toastId: null,
  })

  const startExportMutation = useMutation(
    () => {
      return createExportProcess({ entityType: entityType.id.toString() })
    },
    {
      onMutate: () => {
        const { toastId } = notify('Preparing csv file', { type: 'loading' })

        setActiveExport((prev) => ({
          ...prev,
          toastId: toastId as string,
        }))
      },
      onSuccess: (data) => {
        setActiveExport((prev) => ({
          ...prev,
          exportId: data.id,
        }))
      },
    }
  )

  const getExportDataMutation = useMutation(
    async () => {
      const exportDataRes = await getExportData(activeExport.exportId as string)
      const siteName = activeSite?.name.toLowerCase().replaceAll(' ', '_')
      const fileName = `${slugifyValue(entityType.name)}-${siteName}.csv`
      runBlobDownload(exportDataRes, fileName)
    },
    {
      onSettled: () => {
        if (activeExport.toastId) {
          notify.toast?.dismiss(activeExport.toastId)
        }
        setActiveExport({ exportId: null, toastId: null })
      },
    }
  )

  const activeExportQuery = useQuery(
    ['entities_active_export', activeExport?.exportId],
    () => {
      if (!activeExport?.exportId) {
        throw new Error('No active export')
      }
      return getExportProcess(activeExport.exportId)
    },
    {
      enabled: Boolean(activeExport?.exportId),
      refetchInterval: (q) => {
        if (['new', 'processing', 'prepared'].includes(q?.status as string)) {
          return 20_000
        }
        return false
      },
      onSuccess: (data) => {
        if (data.status === 'completed') {
          getExportDataMutation.mutate()
        }
      },
    }
  )

  useEffect(() => {
    if (!activeExport?.exportId) return

    const listener = (e: any) => {
      const eventData = parseEvent<{
        status: ExportProcess['status']
        metadata: {
          processedItems: number
          totalItems: number
        }
      }>(e)
      // Can be place to show progress but notify update doesn't work
      if (eventData.data.status === 'completed') {
        activeExportQuery.refetch()
      }
    }

    const subscription = subscribe(`/api/entity_exports/${activeExport.exportId}`)

    subscription.addEventListener('message', listener)

    return () => {
      subscription.removeEventListener('message', listener)
      subscription.close()
    }
  }, [activeExport, activeExportQuery, notify, subscribe])

  const isLoading =
    startExportMutation.isLoading || activeExportQuery.isLoading || getExportDataMutation.isLoading

  return {
    startExport: startExportMutation.mutate,
    isLoading,
  }
}
