import React, { FC } from 'react'
import { Box, FormControlLabel, Switch } from '@material-ui/core'
import { getIn, useFormikContext } from 'formik'
import { Attribute } from 'core/types/attribute'
import { AttributeTypesList } from 'common/types'

type Props = {
  attrData: Attribute
}

type Values = {
  type?: string
  setAttributes: { attribute: string; nameable: boolean }[]
}

const relevantAttrTypes: string[] = [
  AttributeTypesList.string,
  AttributeTypesList.integer,
  AttributeTypesList.decimal,
  AttributeTypesList.reference_many_to_one,
  AttributeTypesList.reference_many_to_one_multiple_types,
  AttributeTypesList.reference_one_to_one,
  AttributeTypesList.reference_many_to_one_site,
]

export const RepeaterSettings: FC<Props> = ({ attrData }) => {
  const { values, setFieldValue } = useFormikContext<Values>()

  if (
    values.type !== AttributeTypesList.repeater ||
    !relevantAttrTypes.includes(attrData.attributeType.type)
  )
    return null

  const findValueIndex = values.setAttributes.findIndex(
    (item) => item.attribute === attrData['@id']
  )

  const value = getIn(values, `setAttributes.${findValueIndex}.nameable`)

  return (
    <Box px={1} mb={1}>
      <FormControlLabel
        control={
          <Switch
            edge="start"
            checked={value}
            onChange={(e) => {
              const newValue = values.setAttributes.map((item, index) => {
                return {
                  ...item,
                  nameable: index === findValueIndex ? e.target.checked : false,
                }
              })

              setFieldValue(`setAttributes`, newValue)
            }}
          />
        }
        label="Use as title"
      />
    </Box>
  )
}
