import React, { FC } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'

type Props = {}

export const NoItems: FC<Props> = () => {
  const classes = useStyles()

  return <Box className={classes.root}>No items</Box>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: '1px dashed',
      borderColor: theme.palette.grey[300],
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2.5),
      color: theme.palette.text.disabled,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
)
