// Core
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
// Hooks
import {
  useEntityController,
  useEntityMutation,
  useUnsavedEntity,
  useWidgetsController,
  useInsertTemplate,
  useSimultaneouslyEdit,
  useMutatePersonalizedVersion,
} from '../../hooks'
import useBackupDataOnVersionConflict from 'core/check-version/use-backup-data-on-version-conflict'
import useEntitySettings from 'modules/new-entity/hooks/use-entity-settings'
import { useSitesContext } from 'modules/sites'
import usePreview from '../preview/use-preview'
import useHistory from '../history/use-history'
// Components
import PageHeader from 'modules/layout/components/page-header'
import AttributesForm from '../attributes-form'
import { FormHolder } from '../form-holder'
import PageBuilder from '../page-builder'
import { PageBuilderContext } from '../../context'
import FormActions from '../form-actions'
import InsertTemplate from '../insert-template'
import UpdatedDates from '../updated-dates'
import ResourceVersionSwitcher from 'modules/personalization/resource-version-switcher'
import { ValidationErrorsContext } from 'modules/new-entity/context/validation-errors-context'
import RevisionModeAlert from '../history/revision-mode-alert'
import FormPageSkeleton from 'modules/layout/components/form-page-skeleton'
// Types
import { EavResourceType, MutationType, AsideComponent } from 'modules/new-entity/types'
import { useCloneEntity } from 'modules/eav/entity/clone-entity/use-clone-entity'

type TProps = {
  mutationType: MutationType
  resourceType: EavResourceType
}

const EntityMutationRoute: FC<TProps> = memo(({ mutationType, resourceType }) => {
  const { id, typeId } = useParams<any>()

  /**
   * Controllers for widgets and entity
   * */
  const {
    entity,
    isLoading,
    isFetching,
    attributes,
    mainAttributes,
    initialValues,
    asideAttributes,
    validationSchema,
    entityType,
    formRef,
    mutation,
    setEntity,
    isError,
    refetch,
  } = useEntityController(id, typeId, resourceType, {
    onSaveSuccess: () => {
      updatePreview()
    },
  })

  const isUrlable = entityType?.urlable
  const isGlobalType = entityType?.global
  const isEdit = mutationType === MutationType.EDIT
  const isTemplate = resourceType === EavResourceType.TEMPLATE
  const isGlobalWidgets = resourceType === EavResourceType.WIDGET
  const isRevision = entity?.originalLabel === 'revision'

  const [asideComponents, setAsideComponents] = useState<AsideComponent[]>([])

  const toggleAsideComponents = useCallback((component: AsideComponent, isShowAction?: boolean) => {
    setAsideComponents((prev) => {
      const filteredComponents = prev.filter((item) => item !== component)
      if (!isShowAction) return filteredComponents
      return [...filteredComponents, component]
    })
  }, [])

  /**
   * Preview
   */
  const {
    Preview,
    showPreview,
    updatePreview,
    isOpen: isPreviewOpen,
  } = usePreview(entity, asideComponents, toggleAsideComponents)

  /**
   * History
   */
  const {
    History,
    showHistory,
    isOpen: isHistoryOpen,
    originalPath,
    listPath,
    restoreVersion,
    isRestoring,
  } = useHistory({
    entity,
    entityType,
    isWidgets: isGlobalWidgets,
    asideComponents,
    toggleAsideComponents,
    resourceType,
  })

  const asideHolder = useMemo(() => {
    const asideHolderMap = {
      preview: Preview,
      history: History,
    }

    return <>{asideComponents.map((i) => asideHolderMap[i])}</>
  }, [History, Preview, asideComponents])

  const showVersions =
    !isLoading &&
    !isTemplate &&
    !isRevision &&
    (isUrlable || isGlobalWidgets || entityType?.slug === 'navigations') &&
    isEdit
  const mutatePersonalizedVersion = useMutatePersonalizedVersion(attributes, isGlobalWidgets)

  const entitySettings = useEntitySettings(attributes, formRef.current!, entity, isLoading)

  const {
    siteSettings: {
      colorScheme: { value: colorSchemeValue },
    },
  } = useSitesContext()

  const widgetControllerProps = useWidgetsController(
    entity!,
    entitySettings.options,
    entitySettings.setEntityOption,
    isLoading,
    colorSchemeValue,
    entityType?.builder
  )

  /**
   * Unsaved changes for entity
   * */
  const {
    prompt,
    methods: { savedChanges, checkUnsavedChanges },
  } = useUnsavedEntity(formRef, widgetControllerProps, entity?.entityWidgets || [])

  const {
    saveEntity,
    saveAsTemplate,
    generateEntityValues,
    invalidateForm,
    validationErrors,
    submittedOnce,
  } = useEntityMutation({
    attributes,
    formRef,
    mutation,
    entityType,
    entity: entity!,
    widgets: widgetControllerProps.widgets,
    widgetsDataRefs: widgetControllerProps.widgetsDataRef,
    resourceType,
    isEdit: Boolean(id),
    savedChanges,
    id,
    hasVersions: entityType?.urlable,
  })

  /**
   * Simultaneously Edit
   */
  const { lockScreen, askControlScreen } = useSimultaneouslyEdit(
    id,
    entityType,
    isEdit,
    entity?.template,
    saveEntity,
    mutation.isLoading,
    invalidateForm,
    isRevision
  )

  /**
   * Backup/Restore current entity data in version conflict case
   */
  const setBackupValues = useCallback(
    (newValues) => {
      setEntity((currentValues) => ({
        ...currentValues,
        ...newValues,
      }))
    },
    [setEntity]
  )

  useBackupDataOnVersionConflict({
    data: generateEntityValues,
    enabled: !isLoading && !isError,
    onRestore: setBackupValues,
  })

  /**
   * Insertion template functionality
   * */
  const { insertTemplate, templatesModal, toggleTemplatesModal } = useInsertTemplate(setEntity)

  /**
   * Clone entity
   */
  const { Modal: CloneModal, openModal: openCloneModal } = useCloneEntity(entity?.id)

  /**
   * Highlight attributes from query params
   */
  useEffect(() => {
    if (isLoading) return

    const query = new URLSearchParams(window.location.search)
    if (!query.has('attrs')) return
    setTimeout(() => {
      const params = query.get('attrs')?.split(',') || []
      params.forEach((slug, index) => {
        const el = document.querySelectorAll(`[data-aqa-attribute-slug="${slug}"]`)
        if (!el[0]) return
        el[0].classList.add('highlight')
        if (index === 0) {
          const elYPos = el[0].getBoundingClientRect().top
          window.scrollTo({ top: elYPos, behavior: 'smooth' })
        }
      })
    }, 0)
  }, [isLoading])

  if (isError) {
    return <div>Something went wrong</div>
  }

  if (isLoading) {
    return <FormPageSkeleton hasSidebar />
  }

  return (
    <FormHolder
      disableSidebarScroll={isPreviewOpen || isHistoryOpen}
      dates={
        isEdit && (
          <UpdatedDates
            lastUpdatedAt={entity.updatedAt}
            lastPublishedAt={entity.originalUpdatedAt}
            originalCreatedAt={entity.originalCreatedAt}
          />
        )
      }
      header={
        <PageHeader
          title={isEdit ? entity?.name : `Create new ${entityType.name}`}
          status={entity?.status}
          backPath={listPath}
          actions={
            <FormActions
              originalId={id}
              currentId={entity?.id}
              isUrlable={isUrlable}
              isEdit={isEdit}
              status={entity?.status}
              isTemplate={isTemplate}
              isLoading={mutation.isLoading || isLoading || isFetching || isRestoring}
              saveEntityAction={saveEntity}
              entityType={entityType}
              checkUnsavedChanges={checkUnsavedChanges}
              setSavedChanges={savedChanges}
              insertTemplateAction={toggleTemplatesModal}
              saveAsTemplateAction={saveAsTemplate}
              entityLabel={entity?.originalLabel}
              previewAction={showPreview}
              historyAction={showHistory}
              restoreAction={restoreVersion}
              cloneAction={openCloneModal}
              url={entity?.entityUrls ? entity.entityUrls[0] : undefined}
              isGlobalType={isGlobalType}
            />
          }
        />
      }
    >
      {(asideRef) => (
        <>
          {!isLoading && isRevision && <RevisionModeAlert backPath={originalPath} />}
          {showVersions && (
            <ResourceVersionSwitcher
              resource={resourceType}
              resourceId={+id}
              typeId={typeId}
              entityType={entityType.slug}
              onVersionMutation={(values) =>
                mutatePersonalizedVersion(
                  values.originalId,
                  values.name,
                  values.segments,
                  values.resourceId
                )
              }
              onVersionMutationSuccess={refetch}
              disabled={mutation.isLoading}
              mb={3}
            />
          )}
          <AttributesForm
            asideRef={asideRef}
            type={mutationType}
            mainAttributes={mainAttributes}
            asideAttributes={asideAttributes}
            initialValues={initialValues}
            validationSchema={validationSchema}
            resourceType={resourceType}
            typeOptions={entityType?.options}
            formRef={formRef}
            validateOnMount={submittedOnce}
            disabled={isRevision}
            asideHolder={asideHolder}
            entity={entity}
          />
          {entityType?.builder && !widgetControllerProps.pageBuilderLoading && (
            <PageBuilderContext.Provider value={widgetControllerProps}>
              <ValidationErrorsContext.Provider value={validationErrors}>
                <PageBuilder submittedOnce={submittedOnce} disabled={isRevision} />
              </ValidationErrorsContext.Provider>
            </PageBuilderContext.Provider>
          )}
          {lockScreen}
          {askControlScreen}
          {prompt}
          {CloneModal}
          <InsertTemplate
            insertTemplate={insertTemplate}
            typeId={+entityType?.id}
            isOpen={templatesModal}
            onModalClose={() => toggleTemplatesModal(false)}
          />
        </>
      )}
    </FormHolder>
  )
})

export default EntityMutationRoute
