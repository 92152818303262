// Hooks
import { useSitesContext } from './use-sites-context'

export const useGetActiveSite = () => {
  const { activeSite, data } = useSitesContext()

  const activeSiteData = data?.find((site) => site['@id'] === activeSite['@id'])

  if (!activeSiteData) throw new Error('Site data is required')

  return activeSiteData
}
