import React, { FC, useState } from 'react'
import ActionsPopover from 'common/components/actions-popover'
import { Action } from 'core/data/types/actions'

type Props = {
  canMoveUp?: boolean
  canMoveDown?: boolean
  onRemove?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

export const Actions: FC<Props> = ({ canMoveUp, canMoveDown, onRemove, onMoveUp, onMoveDown }) => {
  const [showActions, setShowActions] = useState(false)

  const actions: Action[] = [
    {
      name: 'move-up',
      icon: 'icon-arrow-up',
      label: 'Move Up',
      condition: !!canMoveUp,
      action: () => {
        onMoveUp?.()
      },
    },
    {
      name: 'move-down',
      icon: 'icon-arrow-down',
      label: 'Move Down',
      condition: !!canMoveDown,
      action: () => {
        onMoveDown?.()
      },
    },
    {
      name: 'remove',
      icon: 'icon-trash-2',
      label: 'Remove',
      condition: true,
      action: () => {
        onRemove?.()
      },
    },
  ]

  return (
    <ActionsPopover
      actions={actions}
      isBtnRef
      showActions={showActions}
      onShowActions={setShowActions}
      anchorOriginHorizontal="center"
      transformOriginHorizontal="center"
      loading={false}
    />
  )
}
