// Core
import React, { FC, useCallback } from 'react'
// Components
import { Edit } from 'core/data'
import CrudForm from '../crud-form'
import Sidebar from '../crud-form/sidebar'
// Hooks
import { useRevalidate } from 'core/hooks/use-revalidate'
// Types
import { ResourceProps } from 'core/data'

const CreateSegments: FC<ResourceProps> = (props) => {
  const { revalidateAll } = useRevalidate()

  const onSuccessHandler = useCallback(async () => {
    await revalidateAll()
  }, [revalidateAll])

  return <Edit {...props} crudForm={CrudForm} sidebar={Sidebar} onSuccess={onSuccessHandler} />
}

export default CreateSegments
