import React, { FC, memo } from 'react'
import { Badge, Box, Paper } from '@material-ui/core'
import { FlattenValue } from '../../types'
import { AttributeData } from 'modules/new-entity/types'
import { getItemTitle } from '../../../../utils'
import { useTitleController } from '../../../../use-title-controller'

type Props = {
  originalItem: FlattenValue
  attrData: AttributeData
  childrenCount: number
}

export const OverlayItem: FC<Props> = memo(({ originalItem, attrData, childrenCount }) => {
  const itemTitle = getItemTitle(originalItem, attrData)

  const { data: title } = useTitleController(itemTitle)

  return (
    <Badge badgeContent={childrenCount} color="primary">
      <Box component={Paper} display="inline-flex" px={2} py={2} mt="5px" ml="15px" minWidth={120}>
        {title}
      </Box>
    </Badge>
  )
})
