import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  Announcements,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'

import {
  buildTree,
  flattenTree,
  getProjection,
  removeChildrenOf,
  removeAndReturnItem,
  addItemAtPosition,
  getChildrenCount,
  getItemDepth,
} from './utils'
import { FlattenValue, SensorContext, Value } from './types'
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates'
import { SortableTreeItem } from './components/sortable-tree-item'
import { CSS } from '@dnd-kit/utilities'
import { AttributeData, EavResourceType, TOptions } from 'modules/new-entity/types'
import { useSitesContext } from 'modules/sites'
import { DefaultValuesGenerator } from 'modules/new-entity/transformers'
import { OverlayItem } from './components/overlay-item'
import { AddItemButton } from 'modules/new-entity/components/shared/array-field/add-item-button'
import { useNotify } from 'core/hooks'

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ]
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    })
  },
}

interface Props {
  name: string
  attrData: AttributeData
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
  required?: boolean
  value: Value[]
  onChange: (value: Value[]) => void
  openedItems: UniqueIdentifier[]
  collapsedItems: UniqueIdentifier[]
  setCollapsedItems: (value: UniqueIdentifier[]) => void
  setOpenedItems: (value: UniqueIdentifier[]) => void
}

const indentationWidth = 30

export function SortableTree({
  value,
  onChange,
  attrData,
  options,
  selfType,
  resourceType,
  disabled,
  required,
  openedItems,
  collapsedItems,
  setOpenedItems,
  setCollapsedItems,
}: Props) {
  const notify = useNotify()

  const { locales } = useSitesContext()
  const localizations = locales.site

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null
    overId: UniqueIdentifier
  } | null>(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(value, attrData['@id'])

    return removeChildrenOf(
      flattenedTree,
      activeId != null ? [activeId, ...collapsedItems] : collapsedItems
    )
  }, [activeId, attrData, value, collapsedItems])

  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  })

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, true, indentationWidth)
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  )

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems])
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    }
  }, [flattenedItems, offsetLeft])

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id)
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id)
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id)
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`
    },
  }

  const skipNestedRepeaters: AttributeData = useMemo(() => {
    return {
      ...attrData,
      setAttributes: attrData.setAttributes?.filter(
        (attr) => attr.attribute.slug !== attrData.slug
      ),
    }
  }, [attrData])

  return (
    <div>
      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          {flattenedItems.map((item) => {
            const { id, children, depth, name, originalIndex, isFirst, isLast } = item

            return (
              <SortableTreeItem
                key={id}
                id={id}
                isFirstInDepth={isFirst}
                isLastInDepth={isLast}
                name={name}
                attrData={skipNestedRepeaters}
                options={options}
                disabled={disabled}
                required={required}
                resourceType={resourceType}
                selfType={selfType}
                originalIndex={originalIndex}
                originalItem={item}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                collapsed={Boolean(collapsedItems.includes(id) && children.length)}
                openedItem={openedItems.includes(id)}
                onToggleItemOpen={() => handleItemOpen(id)}
                onCollapse={children.length ? () => handleCollapse(id) : undefined}
                onRemove={() => handleRemove(id)}
                onAdd={handleAdd}
              />
            )
          })}
          {createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig} modifiers={[adjustTranslate]}>
              {activeId && activeItem && (
                <OverlayItem
                  originalItem={activeItem}
                  attrData={attrData}
                  childrenCount={getChildrenCount(
                    flattenTree(value, attrData['@id']),
                    activeItem.id
                  )}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </SortableContext>
      </DndContext>
      {!disabled && <AddItemButton title="Add item" onClick={() => handleAdd(value.length)} />}
    </div>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      })
    }

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()

    if (projected?.depth && projected.depth > 2) {
      notify('Maximum depth is 3', { type: 'error' })
      return null
    }

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems: FlattenValue[] = JSON.parse(
        JSON.stringify(flattenTree(value, attrData['@id']))
      )
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]

      const itemDepth = getItemDepth(activeTreeItem, attrData['@id'])

      if (projected.depth + itemDepth >= 3) {
        notify('Maximum depth is 3', { type: 'error' })
        return null
      }

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const newItems = buildTree(sortedItems, attrData['@id'])

      onChange(newItems)
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty('cursor', '')
  }

  function handleAdd(position: number, parentId: UniqueIdentifier | null = null) {
    const newItem = new DefaultValuesGenerator(
      attrData.setAttributes || [],
      localizations
    ).getValues(true)

    const newValue = addItemAtPosition(value, attrData['@id'], parentId, newItem, position)

    onChange(newValue)
  }

  function handleRemove(id: UniqueIdentifier) {
    const res = removeAndReturnItem(value, attrData['@id'], id)
    onChange(res.updatedTree)
  }

  function handleCollapse(id: UniqueIdentifier) {
    setCollapsedItems((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }
      return [...prev, id]
    })
  }

  function handleItemOpen(id: UniqueIdentifier) {
    setOpenedItems((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }
      return [...prev, id]
    })
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        }
        setCurrentPosition({
          parentId: projected.parentId,
          overId,
        })
      }

      const clonedItems: FlattenValue[] = JSON.parse(
        JSON.stringify(flattenTree(value, attrData['@id']))
      )
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved'
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested'

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else if (projected.depth > previousItem.depth) {
        announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
      } else {
        let previousSibling: FlattenValue | undefined = previousItem
        while (previousSibling && projected.depth < previousSibling.depth) {
          const { parentId } = previousSibling
          previousSibling = sortedItems.find(({ id }) => id === parentId)
        }

        if (previousSibling) {
          announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
        }
      }

      return announcement
    }
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  }
}
