import { makeStyles } from '@material-ui/core'
import { defaultThemeColors } from 'core/themes/default/default'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingLeft: 'var(--spacing)',
    marginBottom: 8,
  },
  clone: {
    display: 'inline-flex',
    pointerEvents: 'none',
    padding: 0,
    marginLeft: 10,
    marginTop: 5,
    '& .expand-button': {
      display: 'none',
    },
  },
  treeItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
  },
  ghost: {
    '& .tree-item': {
      position: 'relative',
      height: 8,

      '& > *': {
        opacity: 0,
        height: 0,
      },
    },
    '& .tree-item:before, & .tree-item:after': {
      content: '""',
      position: 'absolute',
    },
    '& .tree-item:before': {
      top: 0,
      bottom: 0,
      right: 0,
      left: 30,
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
    },
    '& .tree-item:after': {
      left: 30,
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: '#fff',
      border: `1px solid ${theme.palette.primary.light}`,
    },
  },
  error: {
    '& .tree-item:before': {
      backgroundColor: theme.palette.error.light,
    },
  },
  expandButton: {
    position: 'absolute',
    top: 27 - theme.spacing(3) / 2,
    left: 0,
    width: theme.spacing(3),
    height: theme.spacing(3),
    minWidth: 'auto',
    borderRadius: '50%',
    backgroundColor: defaultThemeColors.contentBg,
  },
  disableInteraction: {
    pointerEvents: 'none',
  },
  disableSelection: {
    userSelect: 'none',
  },
}))
