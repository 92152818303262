import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useNotify } from 'core/hooks'
import { useGetActiveSite } from 'modules/sites'
import { httpService } from 'core/data'
import { EntityService } from '../utils'

type Params = {
  onSuccess?: () => void
  isWidgetType?: boolean
}

export const useDuplicateEntity = (entityId: number, params: Params = {}) => {
  const { onSuccess, isWidgetType } = params
  const activeSite = useGetActiveSite()

  const notify = useNotify()

  const duplicateEntityM = useMutation(
    () => {
      if (isWidgetType) {
        return EntityService.duplicateEntity(entityId, isWidgetType, { status: 'draft' })
      }
      return httpService.post(`/entities/${entityId}/cloning`, { sites: [activeSite['@id']] })
    },
    {
      onSuccess: () => {
        if (onSuccess) onSuccess()
        notify(`Entity duplicated`, { type: 'success' })
      },
    }
  )

  const duplicateEntity = useCallback(() => {
    duplicateEntityM.mutate()
  }, [duplicateEntityM])

  return { duplicateEntityM, duplicateEntity }
}
