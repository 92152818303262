// Core
import React, { FC, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
// Components
import RelationsSelect from 'ui/relations-select'
import EntityRelationsSelect from 'ui/relations-select/entity-relations-select'
// Hooks
import { useNotify } from 'core/hooks'
import { useAppContext } from 'core/app'
// Utils/Services
import { httpService } from 'core/data'
// Types
import { EntityType, HydraResponse } from 'core/types'

type ReplaceTypes = 'text' | 'entity'

type FilterParams = {
  search?: string
  filter?: any
  entityType?: string
}

type Props = {
  initialReplaceFrom: string
  initialReplaceType?: ReplaceTypes
  filterParams?: FilterParams
  onSuccess?: () => void
}

const Replace: FC<Props> = (props) => {
  const { initialReplaceFrom, initialReplaceType, filterParams, onSuccess } = props

  const notify = useNotify()
  const { user } = useAppContext()

  const canChangeReplaceType = user.activeRole.slug === 'architect'

  const [replaceType, setReplaceType] = useState<ReplaceTypes>(initialReplaceType || 'entity')
  const [entityType, setEntityType] = useState('')
  const [replaceFrom, setReplaceFrom] = useState(initialReplaceFrom || '')
  const [replaceTo, setReplaceTo] = useState('')

  const affiliateTypeQ = useQuery(
    'aff-type-query',
    () => {
      return httpService
        .get<HydraResponse<EntityType>>('/entity_types', {
          params: {
            slug: 'affiliate_link',
          },
        })
        .then((res) => res.data['hydra:member'][0])
    },
    {
      onSuccess: (data) => {
        setEntityType(data['@id'])
      },
      cacheTime: 0,
    }
  )

  useEffect(() => {
    setReplaceTo('')

    if (replaceType === 'text') {
      setReplaceFrom(initialReplaceFrom)
    } else {
      setReplaceFrom('')
    }
  }, [replaceType, initialReplaceFrom])

  const replaceM = useMutation(
    () => {
      const params: any = {
        label: ['original', 'draft', 'personalized', 'draft_personalized', 'default'],
      }

      if (filterParams?.search) {
        params.search = filterParams.search
      }

      if (filterParams?.filter) {
        params.filter = filterParams.filter
      }

      if (filterParams?.entityType) {
        params.entityType = filterParams.entityType
      }

      return httpService.post(
        '/entity_replacings',
        {
          type: replaceType,
          search: replaceFrom,
          replace: replaceTo,
        },
        {
          params,
        }
      )
    },
    {
      onSuccess: () => {
        onSuccess?.()

        notify(
          'Success replacing. The process will take place in the background, it may take a while',
          { type: 'success' }
        )
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const title = replaceType === 'entity' ? 'Replace entity' : `Replace text "${replaceFrom}"`

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title}</Typography>
        <TextField
          value={replaceType}
          onChange={(e) => setReplaceType(e.target.value as ReplaceTypes)}
          select
          label="Type of replace"
          variant="outlined"
          size="small"
          style={{ minWidth: 130 }}
          disabled={!canChangeReplaceType}
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="entity">Entity</MenuItem>
        </TextField>
      </Box>
      <Box my={3}>
        {replaceType === 'text' && (
          <>
            <TextField
              value={replaceFrom}
              onChange={(e) => setReplaceFrom(e.target.value)}
              variant="outlined"
              label="Replace from"
              fullWidth
            />
            <Box mt={2}>
              <TextField
                value={replaceTo}
                onChange={(e) => setReplaceTo(e.target.value)}
                variant="outlined"
                label="Replace with"
                fullWidth
              />
            </Box>
          </>
        )}
        {!affiliateTypeQ.isLoading && replaceType === 'entity' && (
          <>
            <RelationsSelect
              value={entityType}
              valueField="@id"
              onChange={(val) => setEntityType(val as string)}
              source="entity_types"
              label="Select entity type"
              disabled={!canChangeReplaceType}
            />
            {entityType && (
              <>
                <Box mt={2}>
                  <EntityRelationsSelect
                    value={replaceFrom}
                    onChange={(val) => setReplaceFrom(val as string)}
                    label="From"
                    reqParams={{ entityType }}
                  />
                </Box>
                <Box mt={2}>
                  <EntityRelationsSelect
                    value={replaceTo}
                    onChange={(val) => setReplaceTo(val as string)}
                    label="To"
                    reqParams={{ entityType }}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <DialogActions>
        <Button
          onClick={() => replaceM.mutate()}
          variant="contained"
          color="primary"
          disabled={!replaceTo || !replaceFrom || replaceM.isLoading}
          endIcon={replaceM.isLoading && <CircularProgress size={16} />}
        >
          Replace
        </Button>
      </DialogActions>
    </>
  )
}

export default Replace
