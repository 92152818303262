import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const groupStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapse: {
      paddingTop: theme.spacing(1),
    },
  })
)

export default groupStyles
