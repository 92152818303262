// eslint-disable-next-line max-classes-per-file
import cloneDeep from 'lodash.clonedeep'
import omit from 'lodash.omit'
import {
  Attribute,
  FormRef,
  IEntityWidget,
  SortedAttribute,
  WidgetsDataRef,
  IEntity,
  IEntityCreate,
  EntityStatus,
} from '../types'
import { referencesParser } from './references-parser'
import { FormikTouched, setNestedObjectValues } from 'formik'
import { httpService } from '../../../core/data'
import { EntityType, HydraResponse } from '../../../core/types'
import { ValuesGenerator } from 'modules/new-entity/transformers'
import { getIdFromIri } from 'core/utils'
import { Site } from 'modules/sites'

function clearEntityValuesIds(data: any, level: number = 0, prevKey: string | null = null): void {
  level = level + 1
  if (Array.isArray(data)) {
    data.forEach((item) => {
      clearEntityValuesIds(item, level, prevKey)
    })
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      const clearKeys = ['id', '@context', '@type', '@id']
      if (data._widgetType) {
        data.widgetType = data._widgetType['@id']
      }
      if (data.widget) {
        delete data.widgetType
      } else if (key === 'widgetType' && level > 1) {
        data[key] = data[key]['@id']
        delete data.widget
      }
      if (prevKey !== '_widgetType' && clearKeys.includes(key)) {
        delete data[key]
      }
      clearEntityValuesIds(data[key], level, key)
    })
  }
}
export class EntityService {
  static generateValues(
    values: any,
    attributes: SortedAttribute[],
    isWidget = false,
    skipIds = false
  ) {
    values = cloneDeep(values)
    referencesParser(values, attributes as any)
    const repeatsKey = isWidget ? 'entitySetRepeats' : 'setRepeats'
    values = new ValuesGenerator(attributes as any, values, repeatsKey, skipIds).getData()

    return values
  }

  static prepareWidgetsValues(
    widgets: IEntityWidget[],
    widgetsDataRef: WidgetsDataRef,
    template = false,
    skipWidgetsTransform = false
  ) {
    widgets = cloneDeep(widgets)

    widgets = widgets.map((widget) => {
      /**
       * Find widget form ref and attributes to generate values per each widget
       */
      const widgetRefData = widgetsDataRef[widget.id]

      if (widgetRefData && !widget.widget) {
        const widgetValues = EntityService.generateValues(
          widgetRefData.formRef.values,
          widgetRefData.attributes,
          true,
          template
        )
        widget.values = widgetValues.values
        widget.entitySetRepeats = widgetValues.entitySetRepeats
      }

      /**
       * Transform widget object relates to created or global
       */
      const { isCreated } = widget

      if (!skipWidgetsTransform) {
        widget._widgetType = widget.widgetType

        if (isCreated || template) {
          delete widget.id
          if (template) {
            EntityService.widgetRemoveIds(
              widget.values,
              widget.entitySetRepeats,
              'entitySetRepeats'
            )
          }
          if (widget.widget) {
            delete widget.widgetType
          } else {
            widget.widgetType = widget.widgetType['@id']
            delete widget.widget
          }
        } else {
          delete widget.widgetType
          if (!widget.widget) delete widget.widget
        }
      }

      return widget
    })

    return widgets
  }

  static async validateForm(formRef: FormRef['current'], id?: any) {
    const errors = (await formRef?.validateForm()) as {}
    const hasErrors = Object.values(errors).length > 0

    if (hasErrors) {
      formRef?.setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true))
    }

    return {
      id,
      hasErrors,
      errors,
    }
  }

  static transformEntityStatus(
    entityData: IEntity,
    status: EntityStatus,
    attributes: SortedAttribute[]
  ): IEntity {
    const statusAttr = attributes.find((attr) => attr.attribute.slug === 'status')

    if (!statusAttr || !status) return entityData

    const statusAttrIri = statusAttr.attribute['@id']

    const statusToValueMap = statusAttr.attribute.attributeEnums.reduce<Record<string, string>>(
      (acc, item) => {
        acc[item.value] = item.id
        return acc
      },
      {}
    )

    const copyEntityData = cloneDeep(entityData)

    const statusValueRecord = copyEntityData.values.find(
      (value) => value.attribute === statusAttrIri
    )

    if (!statusValueRecord) return entityData

    statusValueRecord.value = statusToValueMap[status]

    return copyEntityData
  }

  static async validateWidgets(widgets: IEntityWidget[], widgetsDataRef: WidgetsDataRef) {
    const errors: any = []

    widgets.forEach((widget) => {
      const widgetRefData = widgetsDataRef[widget.id]

      // If exist refData and widget not global
      if (widgetRefData && !widget.widget) {
        errors.push(EntityService.validateForm(widgetRefData.formRef, widget.id))
      }
    })

    return Promise.all(errors)
  }

  static generateAttributes(
    attributesRes: any,
    entityType: EntityType,
    attributesField: 'entityTypeAttributes' | 'widgetTypeAttributes'
  ) {
    const fullAttributes = attributesRes['hydra:member']

    const attributes = entityType[attributesField].map((item) => {
      const finded = fullAttributes.find(
        (attr: Attribute) => item.attribute === attr['@id']
      ) as Attribute
      if (finded.attributeType.type === 'repeater') {
        const findSameChild = finded.setAttributes.find(
          (attr) => attr.attribute.slug === finded.slug
        )

        if (findSameChild) {
          fixRepeater(finded, finded)
        }
      }
      return { ...item, attribute: finded }
    })

    return attributes
  }

  static async getAttributes(resourceName: string, typeId: number) {
    const [
      //
      { data: singleEntityTypeRes },
      { data: attributesRes },
    ] = await Promise.all([
      httpService.get<EntityType>(`/${resourceName}/${typeId}`),
      httpService.get<HydraResponse<Attribute>>(`/${resourceName}/${typeId}/attributes`),
    ])

    return { singleEntityTypeRes, attributesRes }
  }

  static scrollToError(errors: any) {
    const firstErrorAttr = Object.keys(errors)[0]
    const findEl = document.querySelectorAll(`[data-control-id='${firstErrorAttr}']`)

    if (findEl && findEl.length !== 0) {
      findEl[0].scrollIntoView({ behavior: 'smooth' })
    }
  }

  static toggleWidgetErrors(widgetId: any, errors: boolean) {
    const findEl = document.querySelectorAll(`[data-error-id='${widgetId}']`)

    if (findEl && findEl.length === 0) return null

    const errEl: any = findEl[0]
    if (errors) {
      errEl.classList.add('error')
    } else {
      errEl.classList.remove('error')
    }
  }

  static widgetRemoveIds = (values: any[], repeats: any[], repeatField: string) => {
    values.forEach((item) => {
      delete item.id
    })

    repeats.forEach((repeatsItem) => {
      delete repeatsItem.id

      if ((repeatField && repeatsItem[repeatField].length > 0) || repeatsItem.values.length > 0) {
        EntityService.widgetRemoveIds(repeatsItem.values, repeatsItem[repeatField], repeatField)
      }
    })
  }

  static async getEntityData(
    path: 'entities' | 'widgets',
    id: number,
    isUrlable: boolean,
    pathName: string
  ) {
    const isEntitiesRoute = pathName.includes('/entities')
    const entityDataRes = await httpService.get<IEntity>(`/${path}/${id}`)
    const initialEntityData = entityDataRes.data
    let enhancedEntityRes = cloneDeep(entityDataRes)

    enhancedEntityRes.data.originalId = enhancedEntityRes.data.id
    enhancedEntityRes.data.originalVersions = entityDataRes.data.versions

    if (isEntitiesRoute && initialEntityData.template) {
      throw new Error(`Entity with template cannot be edited by '/entities' route`)
    }

    if (initialEntityData.originalLabel === 'draft') {
      throw new Error('Entity with originalLabel "draft" cannot be edited directly')
    }

    if (initialEntityData.status === 'archive') {
      throw new Error('Entity with status "archive" cannot be edited directly')
    }

    const needEnhance = isEntitiesRoute || path === 'widgets'

    if (needEnhance) {
      const isEntityOriginal = initialEntityData.originalLabel === null
      const isRevision = initialEntityData.originalLabel === 'revision'

      if (path === 'entities' && !isRevision && isUrlable) {
        const draftVersion = initialEntityData.versions.find(
          (version) => version.originalLabel === 'draft'
        )

        // Change entity data with draft entity data
        let draftVersionId = null

        if (draftVersion) {
          draftVersionId = draftVersion.id
        }

        if (!draftVersionId) {
          throw new Error('Error with creating draft version')
        }

        enhancedEntityRes = await httpService.get<IEntity>(`/${path}/${draftVersionId}`)
        enhancedEntityRes.data.originalId = initialEntityData.id
        enhancedEntityRes.data.status = initialEntityData.status
        enhancedEntityRes.data.originalUpdatedAt = initialEntityData.updatedAt
        enhancedEntityRes.data.originalCreatedAt = initialEntityData.createdAt
        enhancedEntityRes.data.originalLabel = initialEntityData.originalLabel
        enhancedEntityRes.data.entityUrls = initialEntityData.entityUrls
        enhancedEntityRes.data.originalVersions = initialEntityData.versions
        enhancedEntityRes.data.originalActionName = initialEntityData.actionName
        enhancedEntityRes.data.originalCreatedBy = initialEntityData.createdBy

        // In case if entity is personalized get urls from original entity
        if (!isEntityOriginal && initialEntityData.original) {
          const originalId = getIdFromIri(initialEntityData.original)
          const originalEntityData = await httpService
            .get<IEntity>(`/${path}/${originalId}`)
            .then((res) => res.data)

          enhancedEntityRes.data.originalId = +originalId
          enhancedEntityRes.data.entityUrls = originalEntityData.entityUrls
        }
      }

      if (isRevision) {
        const originalId = getIdFromIri(initialEntityData.original!)
        const originalEntityData = await httpService
          .get<IEntity>(`/${path}/${originalId}`)
          .then((res) => res.data)

        enhancedEntityRes.data.originalId = +originalId
        enhancedEntityRes.data.entityUrls = originalEntityData.entityUrls
        enhancedEntityRes.data.originalVersions = originalEntityData.versions
        enhancedEntityRes.data.originalCreatedAt = originalEntityData.createdAt
        enhancedEntityRes.data.originalCreatedBy = originalEntityData.createdBy
        enhancedEntityRes.data.originalActionName = originalEntityData.actionName
      }

      enhancedEntityRes.data.originalSegments = initialEntityData.segments
    }

    return enhancedEntityRes
  }

  static async saveOriginalEntity(
    originalIri: string,
    newValues: IEntity
  ): Promise<{ id: number }> {
    const originalId = +getIdFromIri(originalIri)

    const copiedData = this.copyEntityData(newValues)

    const createdEntity = await httpService
      .put(`/entities/${originalId}`, copiedData)
      .then((res) => res.data)

    const id = +getIdFromIri(createdEntity['@id'])

    return { id }
  }

  static copyEntityData(entity: IEntity): IEntityCreate {
    const copyEntity = cloneDeep(entity)
    const newData = omit(
      copyEntity,
      'id',
      '@id',
      '@context',
      '@type',
      'title',
      'name',
      'entityUrls',
      'versions'
    )

    newData.original = null
    newData.originalLabel = null

    clearEntityValuesIds(newData)

    return newData
  }

  static previewEntity(activeSite: Site, entityId: number) {
    window.open(
      `${activeSite.url}/api/preview/?entityId=${entityId}&token=${activeSite.token}`,
      '_blank'
    )
  }

  static changeEntityStatus(entityId: number, status: EntityStatus) {
    return httpService.put(`/entity_statuses/${entityId}`, {
      status,
    })
  }

  static async clearSkippedValues(
    isWidgetType: boolean,
    typeId: number,
    entityData: IEntityCreate
  ): Promise<IEntityCreate> {
    const resourceName = isWidgetType ? 'widget_types' : 'entity_types'
    const { attributesRes } = await EntityService.getAttributes(resourceName, typeId)

    const getSafeOption = (options: any) =>
      !Array.isArray(options) ? options?.skip_from_copy : null

    const skipAttrIris = attributesRes['hydra:member'].reduce<string[]>((ids, attr) => {
      const needSkip = getSafeOption(attr.options)
      if (needSkip) ids.push(attr['@id'])
      return ids
    }, [])

    entityData.values = entityData.values.filter((value) => !skipAttrIris.includes(value.attribute))

    return entityData
  }

  static async duplicateEntity(
    entityId: number,
    isWidgetType: boolean = false,
    override?: Partial<IEntity>
  ) {
    const mainPath = isWidgetType ? 'widgets' : 'entities'

    const currentEntity = await httpService
      .get<IEntity>(`/${mainPath}/${entityId}`)
      .then((res) => res.data)

    const typeField = isWidgetType ? 'widgetType' : 'entityType'

    const typeId = +getIdFromIri(currentEntity[typeField])

    let copyValues = EntityService.copyEntityData(currentEntity)
    copyValues = await EntityService.clearSkippedValues(isWidgetType, typeId, copyValues)

    // TODO: Rework (ist quick solution to override values)
    if (override) {
      if (override.segments) {
        copyValues.segments = override.segments
      }
      if (override.original) {
        copyValues.original = override.original
      }
      if (override.originalLabel) {
        copyValues.originalLabel = override.originalLabel
      }
      if (override.values) {
        override.values.forEach((value) => {
          const existingValue = copyValues.values.find(
            (currentValue) => currentValue.attribute === value.attribute
          )
          if (existingValue) {
            existingValue.value = value.value
          } else {
            copyValues.values.push(value)
          }
        })
      }
      if (override.status && !isWidgetType) {
        const attrsRes = await EntityService.getAttributes(
          isWidgetType ? 'widget_types' : 'entity_types',
          typeId
        )

        const attrs = EntityService.generateAttributes(
          attrsRes.attributesRes,
          attrsRes.singleEntityTypeRes,
          'entityTypeAttributes'
        )

        copyValues = EntityService.transformEntityStatus(
          copyValues as IEntity,
          override.status,
          attrs
        )
      }
    }

    const duplicatedEntity = await httpService
      .post<{ data: { '@id': string } }>(`/${mainPath}`, copyValues)
      .then((res) => res.data)

    const createdId = +getIdFromIri(duplicatedEntity['@id'])

    return { id: createdId }
  }

  static async updateEntity(
    entityId: number,
    isWidgetType: boolean = false,
    values?: Partial<IEntity>
  ) {
    const mainPath = isWidgetType ? 'widgets' : 'entities'

    const currentEntity = await httpService
      .get<IEntity>(`/${mainPath}/${entityId}`)
      .then((res) => res.data)

    // TODO: Rework (ist quick solution to override values)
    if (values) {
      if (values.segments) {
        currentEntity.segments = values.segments
      }
      if (values.values) {
        values.values.forEach((value) => {
          const existingValue = currentEntity.values.find(
            (currentValue) => currentValue.attribute === value.attribute
          )
          if (existingValue) {
            existingValue.value = value.value
          } else {
            currentEntity.values.push(value)
          }
        })
      }
    }

    const updatedEntity = await httpService
      .put(`/${mainPath}/${entityId}`, currentEntity)
      .then((res) => res.data)

    const createdId = +getIdFromIri(updatedEntity['@id'])

    return { id: createdId }
  }

  static clearIdsFromEntity(data: any) {
    const copyData = cloneDeep(data)
    clearEntityValuesIds(copyData)
    return copyData
  }

  static restoreEntityVersion(entityId: number, versionId: string, isWidget: boolean) {
    const path = isWidget ? '/widgets' : '/entities'
    return httpService.post(`${path}/${entityId}/restoring`, {
      revision: versionId,
    })
  }
}

export class ValidationError extends Error {
  type: string

  constructor(message: string) {
    super(message)

    this.type = 'validation'
  }
}

function fixRepeater(attr: Attribute, initial: Attribute): boolean {
  if (attr.attributeType.type !== 'repeater' || !attr.setAttributes) {
    return false
  }

  for (const item of attr.setAttributes) {
    if (typeof item.attribute === 'string') {
      const fixInitial: Attribute = {
        ...initial,
        setAttributes: initial.setAttributes.filter((attr) => attr.attribute.slug !== initial.slug),
      }
      item.attribute = JSON.parse(JSON.stringify(fixInitial))
      return true
    }

    if (item.attribute?.setAttributes?.length) {
      const found = fixRepeater(item.attribute, initial)
      if (found) return true
    }
  }

  return false
}
