// Core
import 'core/localization'
import React, { FC } from 'react'
import { createBrowserHistory } from 'history'
import { ToastContainer, toast } from 'react-toastify'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { ReactQueryDevtools } from 'react-query/devtools'
// Components
import { Resource } from 'core/data'
import Dashboard from '../dashboard'
import { Route, Router } from 'core/router'
import { CoreContext } from 'core/components'
import { NotFound } from 'common/components'
import { CoreWebVitalsPage } from 'modules/core-web-vitals'
import Modal from '../modal'
import { ConfirmationWindow } from '../../confirmation'
import { NavigationResource } from 'modules/navigation'
import CheckVersion from 'core/check-version/check-version'
// Resources
import { TypeResource } from 'modules/types-crud'
import { Resource as EntitiesResource } from 'modules/new-entity'
import { MainLayout } from 'modules/layout'
import Loading from '../loading'
import AuthRoutes from 'modules/auth/routes'
// Theme
import { defaultTheme } from 'core/themes'
// Store
import { configureStore } from 'core/utils/configure-store'
import { QueryClient, QueryClientProvider } from 'react-query'
// Types
import { EavResourceType } from 'modules/new-entity/types'
// Styles
import 'react-toastify/dist/ReactToastify.css'
/** CRUD components */
import { SiteCreate, SiteEdit, SiteList, SitesProvider } from 'modules/sites'
import { SegmentsList, CreateSegment, EditSegment } from 'modules/segments'
import { CreateLayout, EditLayout, LayoutList } from 'modules/entity-layouts'
import { UserCreate, UserEdit, UserList } from 'modules/user'
import { SizeCreate, SizeEdit, SizesList } from 'modules/media-sizes'
import { NetworkList, NetworkCreate, NetworkEdit } from 'modules/networks'
import { AppProvider } from '../../app'
import { EventsProvider } from 'core/events'
import { SitemapSettingsPage } from 'modules/sitemap-settings'
import Profile from 'modules/user/pages/profile'
import LayoutHandler from './layout-handler'
import { MediaPage } from 'modules/media/pages/media-page'

const history = createBrowserHistory()
const store = configureStore(history)

const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const Root: FC = () => {
  return (
    <QueryClientProvider client={queryCache}>
      <CoreContext theme={defaultTheme} store={store}>
        <AppProvider>
          <EventsProvider>
            <Router>
              <SitesProvider>
                <LayoutHandler>
                  <Switch>
                    {/* Navigate Resources */}
                    {/* Users Resource */}
                    <Resource path="/users" list={UserList} create={UserCreate} edit={UserEdit} />
                    {/* Layout Resource */}
                    <Resource
                      path="/layouts"
                      list={LayoutList}
                      create={CreateLayout}
                      edit={EditLayout}
                    />
                    {/* Media sizes Resource */}
                    <Resource
                      path="/media_sizes"
                      list={SizesList}
                      create={SizeCreate}
                      edit={SizeEdit}
                    />
                    {/* Sites Resource */}
                    <Resource path="/sites" list={SiteList} create={SiteCreate} edit={SiteEdit} />
                    {/* Networks Resource */}
                    <Resource
                      path="/networks"
                      list={NetworkList}
                      create={NetworkCreate}
                      edit={NetworkEdit}
                    />
                    {/* Media Resource */}
                    <Resource path="/media" list={MediaPage} />
                    {/* Segments Resource */}
                    <Resource
                      path="/segments"
                      list={SegmentsList}
                      create={CreateSegment}
                      edit={EditSegment}
                    />

                    {/* Modified Resources */}
                    {/* Entity Resource */}
                    {/*<EntitiesResource resourceType={EntityResourceType.ENTITY} path="/entities" />*/}

                    {/* New Entity Resource */}
                    <EntitiesResource resourceType={EavResourceType.ENTITY} path="/entities" />
                    {/* Entity Templates */}
                    <EntitiesResource resourceType={EavResourceType.TEMPLATE} path="/templates" />
                    {/* Widgets Resource */}
                    <EntitiesResource resourceType={EavResourceType.WIDGET} path="/widgets" />
                    {/* ET Resource */}
                    <TypeResource path="/entity_types" attrsName="entityTypeAttributes" />
                    {/* WT Resource */}
                    <TypeResource path="/widget_types" attrsName="widgetTypeAttributes" />
                    {/* Media sizes Resource */}
                    <NavigationResource path="/navigations" />

                    {/* General routes */}
                    <Route
                      guard={{ auth: 'user' }}
                      exact
                      key="home"
                      path="/"
                      componentTo={<Dashboard />}
                    />
                    <Route
                      guard={{ auth: 'user' }}
                      exact
                      path="/core-web-vitals"
                      componentTo={<CoreWebVitalsPage />}
                    />
                    <Route
                      guard={{ auth: 'user' }}
                      exact
                      path="/sitemap-settings"
                      componentTo={<SitemapSettingsPage />}
                    />
                    <Route
                      guard={{ auth: 'user' }}
                      exact
                      path="/profile"
                      componentTo={<Profile />}
                    />
                    <AuthRoutes />
                    <Route path="/loading" layout={MainLayout} componentTo={<Loading />} />
                    <Route path="/*" componentTo={<NotFound />} />
                    <Route path="/not-found" componentTo={<NotFound />} />
                  </Switch>
                </LayoutHandler>
                <Modal />
                <ConfirmationWindow />
                <ToastContainer enableMultiContainer position={toast.POSITION.BOTTOM_RIGHT} />
                <ToastContainer
                  enableMultiContainer
                  style={{ width: 420 }}
                  position={toast.POSITION.BOTTOM_RIGHT}
                  containerId="toast-big"
                />
                <CheckVersion />
              </SitesProvider>
            </Router>
          </EventsProvider>
        </AppProvider>
        <ReactQueryDevtools initialIsOpen={false} position="top-right" />
      </CoreContext>
    </QueryClientProvider>
  )
}

export default withTranslation()(Root)
